import { useRouteError } from "react-router-dom";

export default function Error({ status, errors }) {
  let error = useRouteError();
  // console.log(error);
  return (
    <div className="flex h-screen w-screen flex-col justify-center bg-blue-400 pl-4 text-white md:pl-40">
      <div className="text-6xl">{error?.status || status || "Oppsss..."}</div>
      <div className="text-2xl">
        {error?.statusText}
        {Array.isArray(errors) ? (
          errors.map((err) => <p className="mb-0">{err}</p>)
        ) : (
          <p>{errors}</p>
        )}
        {!error?.statusText && !errors ? "Something went wrong." : null}
      </div>
    </div>
  );
}
