const integrators = [
  {
    pos: "POS Nation",
    id: "230908006",
  },
  {
    pos: "POS Bottle",
    id: "240208002",
  },
];

export default integrators;
