import React, { useState } from "react";
import ConfirmModalV2 from "./ConfirmModalV2";
import IntegratorPage from "./IntegratorPage";
import { verifyIntegratorId } from "../helpers/integratorId";

function PaymentDetails(props) {
  const [checkoutModal, setCheckoutModal] = useState(false);

  if (verifyIntegratorId()) {
    return (
      <IntegratorPage
        setModal={setCheckoutModal}
        setStep={props.setStep}
        formData={props.formData}
        setFormData={props.setFormData}
      />
    );
  } else {
    return (
      <div>
        <div>
          <div>
            <div className="mt-4 flex items-center">
              <h2 className="mb-0 w-full text-xl text-primaryDark">
                <b>FREE</b> until receiving iPad
              </h2>
            </div>
            <p className="mb-4 text-sm text-gray-700">
              Starts at $99.00 per month
            </p>

            <div className="mt-96">
              <button
                id="sign-up-btn"
                onClick={() => setCheckoutModal(true)}
                className="block w-full rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:cursor-not-allowed disabled:bg-gray-300"
              >
                Checkout
              </button>
            </div>

            {checkoutModal && (
              <ConfirmModalV2
                setModal={setCheckoutModal}
                setStep={props.setStep}
                formData={props.formData}
                setFormData={props.setFormData}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentDetails;
