import React from "react";
import { useForm } from "react-hook-form";
import { validateUsername } from "../apiRepository/DynamodbRepository";
import LoadingSVG from "./LoadingSVG";

export default function AccountInfoV21(props) {
  const {
    handleSubmit,
    register,
    setError,
    watch,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);

    const response = await validateUsername({ username: data?.username });

    const { usernameExists } = response.data;

    if (usernameExists) {
      setError(
        "username",
        {
          type: "custom",
          message: "Username already taken. Please try another.",
        },
        { shouldFocus: true },
      );
      return;
    }
    props.setFormData({
      ...props.formData,
      account: {
        ...props.formData.account,
        username: data.username,
        password: data.password,
      },
    });
    props.setStep((step) => step + 1);
  };
  return (
    <form
      id="account-details-form"
      className="space-y-48 text-sm text-gray-800"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-2 mt-5 space-y-2">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Username <span className="text-secondary">*</span>
          </label>
          <input
            className={`focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary`}
            type="text"
            {...register("username", {
              required: { value: true, message: "Username is required." },
              validate: (value) => {
                const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
                if (pattern.test(value)) {
                  return "Email address is not allowed.";
                }
              },
            })}
          />
          {errors?.username ? (
            <span className="mt-1 ml-1 mb-2 text-xs text-red-500">
              {errors?.username?.message}
            </span>
          ) : null}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Password <span className="text-secondary">*</span>
          </label>
          <input
            className="focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
            type="password"
            {...register("password", {
              required: { value: true, message: "Password is required." },
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters.",
              },
            })}
          />

          {errors?.password ? (
            <span className="ml-1 text-xs text-red-500">
              {errors?.password?.message}
            </span>
          ) : null}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Confirm Password <span className="text-secondary">*</span>
          </label>
          <input
            className="focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
            type="password"
            {...register("confirmPass", {
              required: {
                value: true,
                message: "Confirm password is required.",
              },
              validate: (value) => {
                if (watch("password") !== value) {
                  return "Password does not match!";
                }
              },
              onChange: () => trigger("confirmPass"),
            })}
          />

          {errors?.confirmPass ? (
            <span className="mt-1 ml-1 mb-2 text-xs text-red-500">
              {errors?.confirmPass?.message}
            </span>
          ) : null}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="block w-1/3 rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? <LoadingSVG /> : "Next"}
        </button>
      </div>
    </form>
  );
}
