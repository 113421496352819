import { useState } from "react";
import deliveryServiceTypes from "../../../constants/deliveryServiceTypes";
import useStore from "../../../hooks/useStore";
import Button from "../Buttons";
import DeliverySettings from "../DeliverySettings";
import DoordashOnBoardingForm from "./DoordashOnBoardingForm";

export default function DoordashDS() {
  const { store } = useStore();
  const [showForm, setShowForm] = useState(false);
  const deliverySetting = store?.deliverySettings?.doordash;

  if (
    (!deliverySetting || Object.keys(deliverySetting).length === 0) &&
    !showForm
  ) {
    return (
      <div className="flex h-[150px] items-center justify-center">
        {/* <button className="bg-red-500 p-3 text-white shadow-lg shadow-red-500/50"></button> */}

        <Button
          label="Enable Delivery Services"
          onClick={() => setShowForm(true)}
          color="green"
        />
      </div>
    );
  }

  if (
    (!deliverySetting || Object.keys(deliverySetting).length === 0) &&
    showForm
  ) {
    return (
      <DoordashOnBoardingForm
        closeForm={() => setShowForm(false)}
        deliveryService={deliveryServiceTypes.doordash.capitalize}
      />
    );
  }

  return (
    <DeliverySettings deliveryService={deliveryServiceTypes.doordash.cammel} />
  );
}
