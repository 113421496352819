import React, { useState } from "react";
import StoreInfo from "./StoreInfo";
import StoreHours from "./StoreHours";
import AccountInfo from "./AccountInfo";
import WelcomePage from "./WelcomePage";
import PaymentDetailsV2 from "./PaymentDetailsV2";
import { parseIntegratorId, verifyIntegratorId } from "../helpers/integratorId";

function Form() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    accountType: "OWNER",
    account: {
      emailAddress: "",
      password: "",
    },
    store: {
      name: "",
      address: {
        street1: "",
        street2: "",
        city: "",
        state: "",
        zipCode: "",
      },
      phoneNumber: "",
      emailAddress: "",
      tax: "",
    },
    operationHours: [
      {
        day: "Sunday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Monday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Tuesday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Wednesday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Thursday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Friday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Saturday",
        startTime: "",
        endTime: "",
      },
    ],
    eula: true,
    newSignUp: true,
    storeId: "",
    integratorId: parseIntegratorId(),
  });

  const formTitles = [
    "Bevz Sign Up Form",
    "Account Information",
    "Store Information",
    "Store Operation Hours",
    "Payment Methods",
  ];

  const formSubTitles = [
    "",
    "Please save this information to log into your Bevz account.",
    "Fill in store details.",
    "Set business hours for your store.",
    "",
  ];

  if (verifyIntegratorId()) {
    formTitles[4] = "Store Details Confirmation";
    formSubTitles[4] = "Review your entered store details.";
  }

  const handlePageDisplay = (step) => {
    if (step === 0) {
      return (
        <WelcomePage
          formData={formData}
          setFormData={setFormData}
          step={step}
          setStep={setStep}
        />
      );
    } else if (step === 1) {
      return (
        <AccountInfo
          formData={formData}
          setFormData={setFormData}
          step={step}
          setStep={setStep}
        />
      );
    } else if (step === 2) {
      return (
        <StoreInfo
          formData={formData}
          setFormData={setFormData}
          step={step}
          setStep={setStep}
        />
      );
    } else if (step === 3) {
      return (
        <StoreHours
          formData={formData}
          setFormData={setFormData}
          step={step}
          setStep={setStep}
        />
      );
    } else if (step === 4) {
      return (
        <PaymentDetailsV2
          formData={formData}
          setFormData={setFormData}
          step={step}
          setStep={setStep}
        />
      );
    }
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-bevz-bg bg-cover">
      <div className="minH-[44rem] sm:minH-[42rem] w-[20rem] rounded-xl bg-white p-6 shadow-2xl sm:w-[32rem] sm:p-8">
        {step !== 0 ? (
          <div className="flex items-center">
            {step === 0 ? (
              ""
            ) : (
              <button onClick={() => setStep((step) => step - 1)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2 h-5 w-5 text-primary"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            )}
            <p className="rem-margin mb-0 text-sm text-secondary">
              Step {step} of {formTitles.length - 1}
            </p>
          </div>
        ) : (
          ""
        )}
        <p className="rem-margin mt-4 text-2xl font-semibold text-primaryDark">
          {formTitles[step]}
        </p>
        <p className="mb-4 text-sm text-form">{formSubTitles[step]}</p>
        <div>{handlePageDisplay(step)}</div>
      </div>
    </div>
  );
}

export default Form;
