import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaStore } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { NavLink, Outlet, useLoaderData } from "react-router-dom";
import useStore from "../../hooks/useStore";
import version from "../../version";
import Error from "../Error";

export async function loader({ request }) {
  const url = new URL(request.url);
  const storeId =
    url.searchParams.get("store_id") || sessionStorage.getItem("storeId");
  const integratorId =
    url.searchParams.get("pos_integrator_id") ||
    sessionStorage.getItem("integratorId");
  const token =
    url.searchParams.get("token") || sessionStorage.getItem("token");
  const callbackUrl =
    url.searchParams.get("callback_url") ||
    sessionStorage.getItem("callbackUrl");

  if (!storeId || !integratorId || !token) {
    throw new Response("", {
      status: 404,
      statusText: "Page not found.",
    });
  }
  sessionStorage.setItem("token", token);

  return { storeId, integratorId, token, callbackUrl };
}

export default function DeliveryServices() {
  const { store, setStore } = useStore();
  const { storeId, integratorId, token, callbackUrl } = useLoaderData();
  const [errMsg, setErrMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscriptionEnded, setIsSubscriptionEnded] = useState(false);
  const subscriptionEndStatus = useMemo(
    () => new Set(["inactive", "canceled"]),
    [],
  );

  useEffect(() => {
    let isMounted = true;
    const getStoreInfo = async () => {
      try {
        const axiosInit = axios.create({
          baseURL: process.env.REACT_APP_BEVZ_API,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const storeDetails = await axiosInit
          .get(
            `/integrator-service/integrators/${integratorId}/stores/${storeId}`,
          )
          .then(({ data }) => data?.data?.[0]);

        if (subscriptionEndStatus.has(storeDetails?.subscriptionStatus)) {
          isMounted && setIsSubscriptionEnded(true);
          return;
        }

        const sensitiveData = await axiosInit
          .get(
            `/integrator-service/integrators/${integratorId}/stores/${storeId}/sensitive-data`,
          )
          .then(({ data }) => data?.data)
          .catch((err) => console.error(err));

        sessionStorage.setItem("storeId", storeId);
        sessionStorage.setItem("integratorId", integratorId);
        sessionStorage.setItem("callbackUrl", callbackUrl);
        const salesTax = sessionStorage.getItem(storeId) || "";
        isMounted && setErrMsg(null);
        isMounted &&
          setStore((prev) => ({
            ...storeDetails,
            integratorId,
            callbackUrl,
            formData: sensitiveData,
            salesTax,
            token,
          }));
      } catch (err) {
        setErrMsg(err?.response?.data?.errors || err.message);
        console.error(err?.response || err);
        console.error(JSON.stringify(err));
      } finally {
        setIsLoading(false);
      }
    };

    getStoreInfo();

    return () => {
      isMounted = false;
    };
  }, [
    integratorId,
    storeId,
    callbackUrl,
    setStore,
    subscriptionEndStatus,
    token,
  ]);

  if (isLoading)
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <CircularProgress disableShrink />
      </div>
    );

  if (errMsg) {
    return <Error errors={errMsg} status={401} />;
  }

  if (isSubscriptionEnded) {
    return (
      <Error
        errors={"Page restricted please update your subscription."}
        status={403}
      />
    );
  }

  return (
    <div className="min-w-screen min-h-scren h-screen overflow-x-hidden bg-slate-100">
      <div className="container mx-auto flex h-full flex-col">
        <Store {...store} callbackUrl={callbackUrl} />
        <Navigation />
        <div className=" max-h-fit flex-1 bg-slate-50">
          <Outlet />
        </div>
        <div className="py-1 text-center text-xs italic text-slate-400">
          POS Integrator v{version}
        </div>
      </div>
    </div>
  );
}

function Store({ storeId, name, email, address, phone, callbackUrl }) {
  return (
    <div className="relative bg-slate-200 p-2 py-4">
      {/* <p className="m-0 text-sm">{storeId}</p> */}
      <p className="m-0 font-bold">
        <FaStore className="inline-block text-gray-400" />
        <span className="pl-1 text-lg">{name}</span>
      </p>
      <p className="m-0 italic">
        <MdEmail className="inline-block text-gray-400" /> {email}
        <span className="pl-2"> </span>
        <BsFillTelephoneFill className="inline-block text-gray-400" /> {phone}
      </p>
      <p className="m-0 italic"></p>
      <p className="m-0">
        <FaMapMarkerAlt className="inline-block text-gray-400" />{" "}
        {address?.street1} {address?.street2} {address?.city} {address?.state}{" "}
        {address?.zipCode}
      </p>
      {callbackUrl ? (
        <div className="absolute top-2 right-2">
          <button
            onClick={() => window.open(callbackUrl, "_self")}
            className="w-28 rounded bg-red-500 py-2 px-4 font-bold text-white "
          >
            CLOSE
          </button>
        </div>
      ) : null}
    </div>
  );
}

function Navigation() {
  const routes = [
    { to: "doordash", name: "DOORDASH", enable: true },
    { to: "grubhub", name: "GRUBHUB", enable: true },
    { to: "ubereats", name: "UBEREATS", enable: true },
    // { to: "bevz", name: "BEVZ", enable: false },
  ];
  return (
    <div className="flex bg-[#00005F]">
      {routes.map((route) => (
        <NavLink
          key={route.name}
          to={`/pos/delivery-services/${route.to}`}
          className={({ isActive, isPending }) =>
            `${
              isActive && route.enable
                ? "bg-blue-900 text-amber-300"
                : route.enable === false
                ? "pointer-events-none text-gray-400"
                : " text-white"
            } mr-2 inline-flex h-full min-w-[100px] items-center justify-center  p-4 font-bold`
          }
        >
          {route.name}
        </NavLink>
      ))}
    </div>
  );
}
