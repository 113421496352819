import integrators from "../constants/exemptedIntegrators";

const verifyIntegratorId = () => {
  const integrator_id = sessionStorage.getItem("integrator_id");

  return integrators.some((integrator) => integrator.id === integrator_id);
};

const parseIntegratorId = () => {
  const integrator_id = sessionStorage.getItem("integrator_id");

  return parseInt(integrator_id) ? integrator_id : "";
};

export { verifyIntegratorId, parseIntegratorId };
