import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "../../components/apiRepository/axios";
import LoadingSVG from "../../components/multistore/LoadingSVG";
import PageHeader from "../../components/multistore/PageHeader";
import useStore from "../../hooks/useStore";

export default function Login() {
  const { setOwner } = useStore();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState([]);

  const handleLogin = async (data) => {
    setApiError([]);

    await axios
      .post("/subscription-service/login", data)
      .then(({ data }) => ({ token: data?.data?.token }))
      .then(({ token }) => {
        setOwner((prev) => ({
          ...prev,
          token,
        }));
        localStorage.setItem("ownerToken", token);
        navigate("/account");
      })
      .catch((err) => {
        console.error(err.response?.data?.errors || err);
        setApiError(err.response?.data?.errors);
      });
  };

  return (
    <div>
      <div>
        <div
          className="flex w-fit cursor-pointer items-center"
          onClick={() => navigate("/")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2 h-5 w-5 text-primary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          <span className="rem-margin text-sm text-secondary">Back</span>
        </div>
      </div>
      <PageHeader
        title={"Log In"}
        subTitle={"Please log into your Bevz account"}
      />
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className="mb-4">
          <label className="block text-xs font-bold text-label2">
            Email Address {/* <span className="text-secondary">*</span> */}
          </label>
          <input
            className={`focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary
                ${errors?.email ? "border-red-400" : ""}`}
            type="text"
            {...register("email", {
              required: {
                value: true,
                message: "Email is required!",
              },
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                message: "Please enter valid email address.",
              },
            })}
          />
          {errors?.email ? (
            <span className="mt-1 ml-1 mb-2 text-xs text-red-500">
              {errors?.email?.message}
            </span>
          ) : null}
        </div>

        <div className="">
          <label className="block  text-xs font-bold text-label2 ">
            Password {/* <span className="text-secondary">*</span> */}
          </label>
          <input
            className={`focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary
                ${errors?.password ? "border-red-400" : ""}`}
            type="password"
            {...register("password", {
              required: {
                value: true,
                message: "Password is required!",
              },
            })}
          />
          {errors?.password ? (
            <span className="mt-1 ml-1 mb-2 text-xs text-red-500">
              {errors?.password?.message}
            </span>
          ) : null}
        </div>
        {(apiError || []).map((error) => (
          <div className="text-xs text-red-500">{error}</div>
        ))}
        <div>
          <button
            className="text-[10px] text-[#6C757D] underline"
            onClick={() => navigate("/forgotpassword")}
            type="button"
          >
            Forgot Password?
          </button>
        </div>

        <div className="mt-9 flex justify-end">
          <button
            className="block w-1/3 rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <LoadingSVG /> : "Next"}
          </button>
        </div>
      </form>
    </div>
  );
}
