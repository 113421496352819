import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import imageReader from "../../../helpers/imageReader";
import useStore from "../../../hooks/useStore";
import { uploadImage } from "../../apiRepository/Ubereats";
import postOnboardForm from "../../apiRepository/postOnboardForm";
import Button from "../Buttons";
import DialogConfirm from "./DialogConfirm";

export default function OnboardingForm({
  closeForm,
  deliveryService,
  hasExistingAccount,
}) {
  const { store } = useStore();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [previewImg, setPreviewImg] = useState();
  const [apiErrors, setApiErrors] = useState(null);
  const [snackbar, setSnackbar] = useState({
    severity: "error",
    message: "",
    open: false,
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      isExisting: hasExistingAccount,
      storeName: store?.name,
      phone: store?.phone,
      street: `${store?.address?.street1} ${store?.address?.street2}`,
      city: store?.address?.city,
      state: store?.address?.state,
      zipCode: store?.address?.zipCode,
    },
  });

  const watchImage = watch("liquorLicense", false);

  const handleCloseSnackbar = (event, reason) => {
    setSnackbar((prev) => ({ ...prev, open: false }));
    closeForm();
  };

  const handleCloseModal = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    setShowConfirmModal(false);
    setApiErrors(null);
  };

  async function submit(data) {
    try {
      const imageBase64 = await imageReader(data?.liquorLicense?.[0]);
      const imagePath = await uploadImage({
        image: {
          base64String: imageBase64.substring(
            imageBase64.indexOf("base64") + 7,
          ),
        },
      });

      if (imagePath?.data?.errorMessage) {
        // eslint-disable-next-line no-throw-literal
        throw `Uploading image failed. Error: ${imagePath.data?.errorMessage}`;
      }

      const res = await postOnboardForm({
        headers: {
          Authorization: `Bearer ${
            store?.token || sessionStorage.getItem("token")
          }`,
        },
        integratorId: store.integratorId,
        storeId: store.storeId,
        deliveryService,
        isExisting: false,
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.contactPhone,
          title: data.title,
          street: data.street,
          city: data.city,
          state: data.state,
          zipCode: data.zipCode,
          storeName: data.storeName,
          liquorLicense: imagePath?.data?.url,
        },
      });
      setApiErrors(null);
      handleCloseModal();
      setSnackbar({
        severity: "success",
        message: res?.message || "Success",
        open: true,
      });
    } catch (err) {
      console.log(err?.response || err);
      setApiErrors(
        err?.response?.data?.errors || err?.message || JSON.stringify(err),
      );
    }
    return;
  }

  useEffect(() => {
    async function imagePreview() {
      if (watchImage?.[0]) {
        const imagePath = await imageReader(watchImage?.[0]);
        setPreviewImg(imagePath);
      }
    }

    imagePreview();
  }, [watchImage]);

  return (
    <>
      <div className="p-4">
        <form
          onSubmit={handleSubmit(() => {
            setShowConfirmModal(true);
          })}
        >
          <div className="mb-10 grid auto-rows-auto grid-cols-2 gap-y-2  gap-x-2 md:gap-x-8">
            <div>
              <div className="font-bold">STORE DETAILS</div>
              <div className="mb-2">
                <label htmlFor="storeName" className="uppercase">
                  Name
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2"
                  type="text"
                  id="storeName"
                  {...register("storeName", {
                    required: "Store name is required.",
                  })}
                />
                <Error message={errors?.storeName?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="phone" className="uppercase">
                  Phone
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2 "
                  type="text"
                  id="phone"
                  {...register("phone", {
                    required: "Phone is required.",
                  })}
                />
                <Error message={errors?.phone?.message} />
              </div>

              <div className="pt-2 font-bold">MAILING/BILLING ADDRESS</div>
              <div className="mb-2">
                <label htmlFor="street" className="block w-full uppercase">
                  Street
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2 "
                  type="text"
                  id="street"
                  {...register("street", {
                    required: "Street is required.",
                  })}
                />
                <Error message={errors?.street?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="city" className="block w-full uppercase">
                  City
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2 "
                  type="text"
                  id="city"
                  {...register("city", {
                    required: "City is required.",
                  })}
                />
                <Error message={errors?.city?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="state" className="block w-full uppercase">
                  State
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2 "
                  type="text"
                  id="state"
                  {...register("state", {
                    required: "City is required.",
                  })}
                />
                <Error message={errors?.state?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="zipCode" className="block w-full uppercase">
                  Zip Code
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2 "
                  type="text"
                  id="zipCode"
                  {...register("zipCode", {
                    required: "Zip code is required.",
                  })}
                />
                <Error message={errors?.zipCode?.message} />
              </div>
            </div>

            <div>
              <div className="font-bold">CONTACT DETAILS</div>
              <div className="mb-2">
                <label htmlFor="firstName" className="uppercase">
                  First Name
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2 "
                  type="text"
                  id="firstName"
                  {...register("firstName", {
                    required: "First name is required.",
                  })}
                />
                <Error message={errors?.firstName?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="lastName" className="uppercase">
                  Last Name
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2 "
                  type="text"
                  id="lastName"
                  {...register("lastName", {
                    required: "Last name is required.",
                  })}
                />
                <Error message={errors?.lastName?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="phone" className="uppercase">
                  Contact Phone
                </label>
                <input
                  className="mb-2 w-full rounded border border-blue-700 p-2 "
                  type="text"
                  id="contactPhone"
                  {...register("contactPhone", {
                    required: "Contact phone is required.",
                  })}
                />
                <Error message={errors?.contactPhone?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="title" className="block uppercase">
                  Title
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2 "
                  type="text"
                  id="title"
                  {...register("title", {
                    required: "Title is required.",
                  })}
                />
                <Error message={errors?.title?.message} />
              </div>
              <div className="mb-2 pt-2 font-bold">LIQUOR LICENSE</div>
              <div className="">
                {/* <label
                  htmlFor="liquorLicense"
                  className="block w-full uppercase"
                >
                  Liquor License
                </label> */}
                <input
                  className="w-full rounded border border-blue-700 p-2 "
                  type="file"
                  accept="image/*"
                  {...register("liquorLicense", {
                    required: "Liquor License is required.",
                  })}
                />
                <Error message={errors?.liquorLicense?.message} />
              </div>
              <div className="pt-4">
                {previewImg ? (
                  <img
                    src={previewImg}
                    alt="Preview"
                    className="h-60 rounded"
                  />
                ) : null}
              </div>
            </div>
          </div>

          <div className="text-right">
            <Button
              loading={isSubmitting}
              type="submit"
              label="S U B M I T"
              width={48}
              margin={"mr6"}
              color="green"
            />
            <Button
              loading={isSubmitting}
              type="button"
              label=" C A N C E L"
              width={48}
              color="red"
              onClick={closeForm}
            />
          </div>
        </form>
      </div>
      <DialogConfirm
        handleCloseModal={handleCloseModal}
        handleConfirm={handleSubmit(submit)}
        isSubmitting={isSubmitting}
        showModal={showConfirmModal}
        errors={apiErrors}
        clearError={() => setApiErrors(null)}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const Error = ({ message }) =>
  message ? <p className="m-0 text-xs text-red-600">{message}</p> : null;
