import axios from "axios";
import configs from "../configs";

const header = {
  "Content-Type": "application/json",
  "Access-Control-Request-Method": "POST",
};

const instance = axios.create({
  baseURL: configs.api.baseUrl,
  //timeout: 20000,
});

export const validateEmail = (email) =>
  instance.post(`/validate-email`, email, { header }).then((res) => {
    if (res.data.errorMessage) {
      throw new Error(res.data.errorMessage);
    }
    return res.data;
  });

export const validateUsername = (username) =>
  instance.post(`/validate-username`, username, { header }).then((res) => {
    if (res.data.errorMessage) {
      throw new Error(res.data.errorMessage);
    }
    return res.data;
  });

export const getStoreSubscriptionDetails = ({ storeId, header }) =>
  instance.get(`/subscription-details/${storeId}`, header).then((res) => {
    if (res.data.errorMessage) {
      throw new Error(res.data.errorMessage);
    }
    return res.data?.data || {};
  });

export const postForgotPassword = ({ email }) =>
  instance.post("/forgot-password", { email }, header).then((res) => res.data);

export const postResetPassword = ({ password, id, token }) =>
  instance
    .post("/reset-password", { password, id, token }, header)
    .then((res) => res.data);

export const checkAuth = ({ token, config }) =>
  instance
    .post("/check-auth", { token }, { header, ...config })
    .then((res) => res.data);

export const generateCheckoutLink = ({ token, storeId }) =>
  instance
    .get(`/generate-checkout-link/${storeId}`, { token }, { header })
    .then((res) => res.data?.body?.checkoutLink);
