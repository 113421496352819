import { axiosPrivate } from "./axios";

const postOnboardForm = async ({
  integratorId,
  storeId,
  deliveryService,
  isExisting,
  data,
  headers,
}) =>
  await axiosPrivate
    .post(
      `/integrator-service/integrators/${integratorId}/stores/${storeId}/onboard-delivery-services`,
      {
        deliveryService,
        isExisting,
        data,
      },
      { headers },
    )
    .then(({ data }) => data);

export default postOnboardForm;
