const TYPEOFENTITY = [
  {
    name: "Sole Proprietorship",
    code: "sole_proprietorship",
  },
  {
    name: "Partnership",
    code: "partnership",
  },
  {
    name: "C Corporation",
    code: "c_corporation",
  },
  {
    name: "S Corporation",
    code: "s_corporation",
  },
  {
    name: "Limited Liability Company (LLC)",
    code: "limited_liability_company",
  },
];

export default TYPEOFENTITY;
