import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { TimePicker } from "antd";
import "antd/dist/antd.css";

function StoreHours(props) {
  const { handleSubmit } = useForm();
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState({
    monday: ["", ""],
    tuesday: ["", ""],
    wednesday: ["", ""],
    thursday: ["", ""],
    friday: ["", ""],
    saturday: ["", ""],
    sunday: ["", ""],
  });

  const handleMonday = (time, timeString) => {
    props.formData.operationHours[1].startTime = timeString[0];
    props.formData.operationHours[1].endTime = timeString[1];
    props.setFormData({
      ...props.formData,
      operationHours: [...props.formData.operationHours],
    });
    value.monday = [time[0], time[1]];
    setValue({
      ...value,
      monday: [...value.monday],
    });

    // if (checked) {
    //   props.setFormData({
    //     ...props.formData,
    //     operationHours: props.formData.operationHours.map((x) => ({
    //       ...x,
    //       startTime: props.formData.operationHours[1].startTime,
    //       endTime: props.formData.operationHours[1].endTime,
    //     })),
    //   });
    //   value.tuesday = value.monday;
    //   value.wednesday = value.monday;
    //   value.thursday = value.monday;
    //   value.friday = value.monday;
    //   value.saturday = value.monday;
    //   value.sunday = value.monday;

    //   setValue({
    //     ...value,
    //   });
    // }
  };

  const handleTuesday = (time, timeString) => {
    props.formData.operationHours[2].startTime = timeString[0];
    props.formData.operationHours[2].endTime = timeString[1];
    props.setFormData({
      ...props.formData,
      operationHours: [...props.formData.operationHours],
    });
    value.tuesday = [time[0], time[1]];
    setValue({
      ...value,
      tuesday: [...value.tuesday],
    });
  };

  const handleWednesday = (time, timeString) => {
    props.formData.operationHours[3].startTime = timeString[0];
    props.formData.operationHours[3].endTime = timeString[1];
    props.setFormData({
      ...props.formData,
      operationHours: [...props.formData.operationHours],
    });
    value.wednesday = [time[0], time[1]];
    setValue({
      ...value,
      wednesday: [...value.wednesday],
    });
  };

  const handleThursday = (time, timeString) => {
    props.formData.operationHours[4].startTime = timeString[0];
    props.formData.operationHours[4].endTime = timeString[1];
    props.setFormData({
      ...props.formData,
      operationHours: [...props.formData.operationHours],
    });
    value.thursday = [time[0], time[1]];
    setValue({
      ...value,
      thursday: [...value.thursday],
    });
  };

  const handleFriday = (time, timeString) => {
    props.formData.operationHours[5].startTime = timeString[0];
    props.formData.operationHours[5].endTime = timeString[1];
    props.setFormData({
      ...props.formData,
      operationHours: [...props.formData.operationHours],
    });
    value.friday = [time[0], time[1]];
    setValue({
      ...value,
      friday: [...value.friday],
    });
  };

  const handleSaturday = (time, timeString) => {
    props.formData.operationHours[6].startTime = timeString[0];
    props.formData.operationHours[6].endTime = timeString[1];
    props.setFormData({
      ...props.formData,
      operationHours: [...props.formData.operationHours],
    });
    value.saturday = [time[0], time[1]];
    setValue({
      ...value,
      saturday: [...value.saturday],
    });
  };

  const handleSunday = (time, timeString) => {
    props.formData.operationHours[0].startTime = timeString[0];
    props.formData.operationHours[0].endTime = timeString[1];
    props.setFormData({
      ...props.formData,
      operationHours: [...props.formData.operationHours],
    });
    value.sunday = [time[0], time[1]];
    setValue({
      ...value,
      sunday: [...value.sunday],
    });
  };

  const handleApplyTimeToAll = () => {
    setChecked(!checked);
    if (!checked) {
      props.setFormData({
        ...props.formData,
        operationHours: props.formData.operationHours.map((x) => ({
          ...x,
          startTime: props.formData.operationHours[1].startTime,
          endTime: props.formData.operationHours[1].endTime,
        })),
      });
      value.tuesday = value.monday;
      value.wednesday = value.monday;
      value.thursday = value.monday;
      value.friday = value.monday;
      value.saturday = value.monday;
      value.sunday = value.monday;

      setValue({
        ...value,
      });
    }
  };

  // store hours validation
  const hasEmptyValues = (obj) => {
    for (let day in obj) {
      if (obj.hasOwnProperty(day)) {
        if (obj[day].some(value => value !== "")) {
          return false;
        }
      }
    }
    return true;
  };

  const onSubmit = () => {
    //setModal(!modal);
    props.setStep((step) => step + 1);
  };

  return (
    <div>
      <form
        id="store-operation-details-form"
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-6 mt-8  text-gray-800 text-sm"
      >
        <div className="grid grid-cols-3 gap-0.5">
          <div>
            <div className="flex items-center">
              <div className="text-primary">Monday</div>
            </div>
            <label className="text-primary text-xs">
              <input
                className="h-3 w-3 mr-2  accent-primary cursor-pointer rounded"
                type="checkbox"
                onChange={handleApplyTimeToAll}
                disabled={value.monday[0] === ""}
              />
              Apply to all
            </label>
          </div>
          <div className="col-span-2">
            <TimePicker.RangePicker
              use12Hours
              format="h:mm A"
              onChange={handleMonday}
              value={value.monday}
              size="large"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-0.5">
          <div>
            <div className="flex items-center">
              <div className="text-primary">Tuesday</div>
            </div>
          </div>
          <div className="col-span-2">
            <TimePicker.RangePicker
              use12Hours
              format="h:mm A"
              onChange={handleTuesday}
              value={value.tuesday}
              size="large"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-0.5">
          <div>
            <div className="flex items-center">
              <div className="text-primary">Wednesday</div>
            </div>
          </div>
          <div className="col-span-2">
            <TimePicker.RangePicker
              use12Hours
              format="h:mm A"
              onChange={handleWednesday}
              value={value.wednesday}
              size="large"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-0.5">
          <div>
            <div className="flex items-center">
              <div className="text-primary">Thursday</div>
            </div>
          </div>
          <div className="col-span-2">
            <TimePicker.RangePicker
              use12Hours
              format="h:mm A"
              onChange={handleThursday}
              value={value.thursday}
              size="large"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-0.5">
          <div>
            <div className="flex items-center">
              <div className="text-primary">Friday</div>
            </div>
          </div>
          <div className="col-span-2">
            <TimePicker.RangePicker
              use12Hours
              format="h:mm A"
              onChange={handleFriday}
              value={value.friday}
              size="large"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-0.5">
          <div>
            <div className="flex items-center">
              <div className="text-primary">Saturday</div>
            </div>
          </div>
          <div className="col-span-2">
            <TimePicker.RangePicker
              use12Hours
              format="h:mm A"
              onChange={handleSaturday}
              value={value.saturday}
              size="large"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-0.5">
          <div>
            <div className="flex items-center">
              <div className="text-primary">Sunday</div>
            </div>
          </div>
          <div className="col-span-2">
            <TimePicker.RangePicker
              use12Hours
              format="h:mm A"
              onChange={handleSunday}
              value={value.sunday}
              size="large"
            />
          </div>
        </div>

        <div className="flex justify-end text-sm">
          <button
            className="block bg-primary p-2 text-white rounded hover:bg-secondary transition duration-300 w-1/3 disabled:bg-gray-400"
            type="submit"
            disabled={hasEmptyValues(value)}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

export default StoreHours;
