export const validateOwner = (values) => {
  let errors = {};
  const nameRegex = /^[a-zA-Z ']+$/;
  const einRegex = /^\d{9}$/;
  const ssnRegex = /^\d{4}$/;
  const dobRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(18|19|20)\d{2}$/;

  if (!values.owner.name) {
    //errors.name = "Name is required!";
  } else if (!nameRegex.test(values.owner.name)) {
    errors.name = "Please enter valid name.";
  } else if (!values.owner.dob) {
    //errors.ein = "Employer Identification Number is required!";
  } else if (!dobRegex.test(values.owner.dob)) {
    errors.dob = "Please enter birthdate in MM/DD/YYYY format only.";
  } else if (!values.owner.ein) {
    //errors.ein = "Employer Identification Number is required!";
  } else if (!einRegex.test(values.owner.ein)) {
    errors.ein = "Please enter 9-digit Employer ID Number (EIN).";
  } else if (!values.owner.ssn) {
    //errors.ein = "Employer Identification Number is required!";
  } else if (!ssnRegex.test(values.owner.ssn)) {
    errors.ssn = "Please enter last 4 digits of your SSN.";
  }
  errors.isNameValid = errors.name !== undefined ? true : false;
  errors.isDobValid = errors.dob !== undefined ? true : false;
  errors.isEinValid = errors.ein !== undefined ? true : false;
  errors.isSsnValid = errors.ssn !== undefined ? true : false;
  errors.isValid =
    errors.isNameValid ||
    errors.isDobValid ||
    errors.isEinValid ||
    errors.isSsnValid;
  return errors;
};

export const validateCredentials = (values) => {
  let errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if (!values.account.emailAddress) {
    //errors.email = "Email is required!";
  } else if (!emailRegex.test(values.account.emailAddress)) {
    errors.email = "Please enter valid email address.";
  } else if (!values.account.password) {
    //errors.password = "Email is required!";
  } else if (values.account.password.length < 8) {
    errors.password = "Password must be at least 8 characters.";
  }

  errors.isEmailValid = errors.email !== undefined ? true : false;
  errors.isPasswordValid = errors.password !== undefined ? true : false;
  errors.isValid = errors.isEmailValid || errors.isPasswordValid;
  return errors;
};

export const validateStore = (values) => {
  let errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const nameRegex = /^[a-zA-Z ']+$/;
  const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

  if (!values.store.name) {
    //errors.email = "Email is required!";
  } else if (!values.store.address.zipCode) {
    //errors.phoneNumber = "Phone is required!";
  } else if (!zipRegex.test(values.store.address.zipCode)) {
    errors.zip = "Please enter 5-digit zip code.";
  } else if (!values.store.emailAddress) {
    //errors.managerName = "Email is required!";
  } else if (!emailRegex.test(values.store.emailAddress)) {
    errors.emailAddress = "Please enter valid email address.";
  } else if (!values.store.managerName) {
    //errors.managerName = "Email is required!";
  } else if (!nameRegex.test(values.store.managerName)) {
    errors.managerName = "Please enter valid name.";
  } else if (!values.store.managerEmailAddress) {
    //errors.email = "Email is required!";
  } else if (!emailRegex.test(values.store.managerEmailAddress)) {
    errors.managerEmailAddress = "Please enter valid email address.";
  }

  errors.isZipValid = errors.zip !== undefined ? true : false;
  errors.isManagerNameValid = errors.managerName !== undefined ? true : false;
  errors.isManagerEmailValid =
    errors.managerEmailAddress !== undefined ? true : false;
  errors.isEmailValid = errors.emailAddress !== undefined ? true : false;
  errors.isValid =
    errors.isZipValid ||
    errors.isEmailValid ||
    errors.isManagerNameValid ||
    errors.isManagerEmailValid;
  return errors;
};
