import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { validateEmail } from "./apiRepository/DynamodbRepository";
import { validateCredentials } from "./validation/validation";

function AccountInfo(props) {
  const { emailAddress, password } = props.formData.account;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const [error, setError] = useState(false);
  const [confirmPass, setConfirmPass] = useState();

  const { handleSubmit } = useForm({});

  const onChangeAccountDetails = (e) => {
    setEmail(false);
    props.setFormData({
      ...props.formData,
      account: {
        ...props.formData.account,
        [e.target.name]: e.target.value,
      },
    });
    if (e.target.name === "password" && confirmPass !== undefined) {
      if (e.target.value === confirmPass) {
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  const onChangePass = (e) => {
    if (password === e.target.value) {
      setError(false);
    } else {
      setError(true);
    }
    setConfirmPass(e.target.value);
  };

  const onSubmit = async () => {
    setLoading(true);
    const response = await validateEmail({ emailAddress });
    const { emailExists, store } = response.data;
    setEmail(emailExists);
    if (!emailExists) {
      props.setStep((step) => step + 1);
    }
    // if (!store?.signUpCompleted) {
    //   props.setFormData({
    //     ...props.formData,
    //     newSignUp: store.signUpCompleted,
    //     storeId: store.storeId,
    //     store: {
    //       ...props.formData.store,
    //       name: store.name,
    //       address: {
    //         street1: store.address.street1,
    //         street2: store.address.street2,
    //         city: store.address.city,
    //         state: store.address.state,
    //         zipCode: store.address.zipCode,
    //       },
    //       phoneNumber: store.phone,
    //       emailAddress: store.email,
    //     },
    //   });
    //   props.setStep((step) => step + 1);
    // }
    setLoading(false);
  };
  return (
    <form
      id="account-details-form"
      className="space-y-48 text-sm text-gray-800"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-2 mt-5 space-y-2">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email address <span className="text-secondary">*</span>
          </label>
          <input
            className={`focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary
                ${email ? "border-red-400" : ""}`}
            value={emailAddress}
            name="emailAddress"
            onChange={onChangeAccountDetails}
            type="text"
            required
          />
          <span className="mt-1 ml-1 mb-2 text-xs text-red-500">
            {validateCredentials(props.formData).isEmailValid
              ? validateCredentials(props.formData).email
              : undefined}
          </span>
          <span className="mt-1 mb-2 text-xs text-red-500">
            {email ? "Email address already exists." : undefined}
          </span>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Password <span className="text-secondary">*</span>
          </label>
          <input
            className="focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
            type="password"
            value={password}
            name="password"
            onChange={onChangeAccountDetails}
            required
          />
          <span className="ml-1 text-xs text-red-500">
            {validateCredentials(props.formData).password}
          </span>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Confirm Password <span className="text-secondary">*</span>
          </label>
          <input
            className="focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
            type="password"
            value={confirmPass}
            name="confirmPass"
            onChange={onChangePass}
            required
          />
          <span className="mt-1 ml-1 mb-2 text-xs text-red-500">
            {error ? "Password does not match!" : undefined}
          </span>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="block w-1/3 rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
          type="submit"
          disabled={validateCredentials(props.formData).isValid || error}
        >
          {loading ? (
            <div className="flex justify-center">
              <svg
                className="-ml-1 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            "Next"
          )}
        </button>
      </div>
    </form>
  );
}

export default AccountInfo;
