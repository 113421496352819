import axios from "axios";
import configs from "../configs";

const header = {
  "Content-Type": "application/json",
  "Access-Control-Request-Method": "POST",
};

const instance = axios.create({
  baseURL: configs.api.baseUrl,
  //timeout: 20000,
});

export const validateAddress = (address) =>
  instance.post(`/validate-address`, address, { header }).then((res) => {
    if (res.data.errorMessage) {
      throw new Error(res.data.errorMessage);
    }
    return res.data;
  });
