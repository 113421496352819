import React from "react";
import ReactDOM from "react-dom";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Form from "./components/Form.js";
import LandingPage from "./components/LandingPage";
import WelcomePage, {
  loader as welcomePageLoader,
} from "./components/WelcomePage.js";
import TempoUnavailableDS from "./components/deliveryServices/TempoUnavailableDS";
import DoordashDS from "./components/deliveryServices/doordash/Doordash";
import GrubHubNotice from "./components/deliveryServices/grubhub/GrubHubNotice";
import GrubHubDS from "./components/deliveryServices/grubhub/Grubhub";
import UberEatsDS from "./components/deliveryServices/ubereats/UberEats";
import StoreProvision, {
  loader as storeProvisionLoader,
} from "./components/deliveryServices/ubereats/provision/StoreProvision";
import UberEatsProvisioning, {
  loader as ubereatsProvisioningLoader,
} from "./components/deliveryServices/ubereats/provision/UberEatsProvisioning";
import { StoreProvider } from "./context/StoreProvider";
import "./index.css";
import Error from "./pages/Error";
import Error404 from "./pages/Error404";
import FormCreateStore from "./pages/multistore/FormCreateStore.js";
import Index from "./pages/multistore/Index.js";
import Login from "./pages/multistore/Login.js";
import StoreList from "./pages/multistore/StoreList.js";
import DeliveryServices, {
  loader as deliveryServicesLoader,
} from "./pages/pos/DeliveryServices";
import ForgotPassword from "./pages/ForgotPassword.js";
import ResetPassword, {
  loader as resetPassworfLoader,
} from "./pages/ResetPassword.js";
import RequireAuth, {
  loader as requireAuthLoader,
} from "./components/multistore/RequireAuth.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
    children: [
      {
        index: true,
        element: <WelcomePage />,
        loader: welcomePageLoader,
      },
      { path: "/login", element: <Login /> },
      { path: "/forgotpassword", element: <ForgotPassword /> },
      {
        path: "/reset-password/:userId/:token",
        element: <ResetPassword />,
        loader: resetPassworfLoader,
      },
      {
        path: "/account",
        element: <RequireAuth />,
        loader: requireAuthLoader,
        children: [
          { index: true, element: <StoreList /> },
          { path: "store", element: <FormCreateStore /> },
        ],
      },
      {
        path: "/pos_onboarding",
        element: <WelcomePage />,
        loader: welcomePageLoader,
      },
    ],
  },
  { path: "/signup", element: <Form /> },
  { path: "/success", element: <LandingPage /> },
  { path: "/canceled", element: <ErrorPage /> },
  {
    path: "/ubereats",
    element: <UberEatsProvisioning />,
    errorElement: <Error />,
    loader: ubereatsProvisioningLoader,
  },
  {
    path: "ubereats/:action",
    element: <StoreProvision />,
    errorElement: <Error />,
    loader: storeProvisionLoader,
  }, // route was separated because UberEats accesstoken given once
  {
    path: "/pos",
    element: <Outlet />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Navigate to={"delivery-services"} replace /> },
      {
        path: "delivery-services",
        element: <DeliveryServices />,
        loader: deliveryServicesLoader,
        children: [
          {
            index: true,
            element: <Navigate to={"doordash"} replace />,
          },
          { path: "bevz", element: <TempoUnavailableDS /> },
          { path: "doordash", element: <DoordashDS /> },
          { path: "grubhub", element: <GrubHubDS /> },
          { path: "grubhub/notice", element: <GrubHubNotice /> },
          { path: "ubereats", element: <UberEatsDS /> },
          // { path: "testform", element: <TestFormDS /> },
        ],
      },
    ],
  },
  { path: "*", element: <Error404 /> },
]);

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <RouterProvider router={router} />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
