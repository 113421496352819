import { Navigate, Outlet, redirect, useLoaderData } from "react-router-dom";
import useStore from "../../hooks/useStore";
import { useEffect } from "react";
import { checkAuth } from "../apiRepository/DynamodbRepository";

export function loader() {
  const token = localStorage.getItem("ownerToken");

  if (!token) return redirect("/login");

  return { token };
}

export default function RequireAuth() {
  const { setOwner } = useStore();
  const { token } = useLoaderData();

  useEffect(() => {
    const controller = new AbortController();

    async function getUserInfo() {
      const userInfo = await checkAuth({
        token,
        config: { signal: controller.signal },
      }).then((res) => res?.data);

      setOwner((prev) => ({
        ...prev,
        ownerId: userInfo?.id,
        storeId: userInfo?.sub,
        token,
      }));
      localStorage.setItem("ownerId", userInfo?.id);
      localStorage.setItem("ownerStoreId", userInfo?.sub);
    }

    token && getUserInfo();

    return () => {
      controller.abort();
    };
  }, [token, setOwner]);

  return <Outlet />;
}
