export default function StorePricingTier() {
  return (
    <div>
      <h2 className="mb-0 font-bold">Store Pricing Tiers</h2>
      <p className="text-label2 text-xs">
        1 - 4: $99 per month per store
        <br />
        5 - 14: $79 per month per store
        <br />
        15+: $59 permonth per store
      </p>
    </div>
  );
}
