import { axiosPrivate } from "./axios";

const getUbereatsStores = async ({ code, storeId }) =>
  await axiosPrivate
    .post(
      `/ubereats-service/v2/post-exchange-code-for-store-list`,
      {
        authorization_code: code,
        store_id: storeId,
        redirect_uri: `${process.env.REACT_APP_UBER_REDIRECT_URI}?provision_type=add`,
      },
      {
        headers: {
          client: "INTEGRATOR",
        },
      },
    )
    .then(({ data }) => ({
      accessToken: data?.data?.access_token,
      stores: data?.data?.uber_stores,
    }));

const postProvisionStore = async ({
  accessToken,
  ubereatsStoreId,
  storeId,
  action,
}) => {
  const url =
    action === "deprovision"
      ? `/ubereats-service/v2/store/${storeId}/de-provision-store`
      : `/ubereats-service/v2/store/${storeId}/provision-store`;
  return await axiosPrivate
    .post(
      url,
      {
        access_token: accessToken,
        selected_store_id: ubereatsStoreId,
        store_id: storeId,
      },
      {
        headers: {
          client: "INTEGRATOR",
        },
      },
    )
    .then(({ data }) => data);
};

const uploadImage = async ({ image }) =>
  await axiosPrivate.put("/v1/image", image, {
    headers: {
      client: "INTEGRATOR",
      Authorization: "Basic YmV2ejIwMjM6QmV2ekAxMjM0NSE=",
    },
  });

export {
  getUbereatsStores as default,
  getUbereatsStores,
  postProvisionStore,
  uploadImage,
};
