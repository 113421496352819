import { createContext, useEffect, useState } from "react";

const StoreContext = createContext({});

export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState({});
  const [owner, setOwner] = useState({});

  useEffect(() => {
    try {
      const ownerId = localStorage.getItem("ownerId");
      const storeId = localStorage.getItem("ownerStoreId");
      const token = localStorage.getItem("ownerToken");
      setOwner({ ownerId, storeId, token });
    } catch (error) {}
  }, []);

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
        owner,
        setOwner,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
