import React from "react";
import Error from "../assets/images/error.png";

function ErrorPage() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-bevz-bg bg-cover">
      <div className="bg-white sm:p-8 p-6 shadow-2xl sm:w-[32rem] w-[20rem] h-[42rem] rounded-xl">
        <div className="space-y-10 mt-24" id="error-page">
          <div className="flex flex-col items-center">
            <img className="h-20 w-20" src={Error} alt=""></img>
          </div>
          <div className="font-bold text-xl text-center text-primary">
            Your subscription did not go through.
            <div className="text-sm text-center text-gray-500 mt-5">
              If you ever change your mind, <br />
              please give us a call at +1-818-538-7292, or email us at <br />
              <a href="mailto:support@bevz.com?subject=Bevz Support">
                support@bevz.com
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <a
              className="block bg-primary p-2 text-white text-center rounded hover:bg-secondary transition duration-300 w-1/3 disabled:bg-gray-400"
              href="https://www.bevz.com/"
            >
              Back to Bevz
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
