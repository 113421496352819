import React from "react";
import Email from "../assets/images/email.png";

function LandingPage(props) {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-bevz-bg bg-cover">
      <div className="bg-white sm:p-8 p-6 shadow-2xl sm:w-[32rem] w-[20rem] h-[42rem] rounded-xl">
        <div className="space-y-10 mt-28" id="thank-you-page">
          <div className="flex flex-col items-center">
            <img src={Email} alt=""></img>
          </div>
          <div className="font-bold text-xl text-center text-primary">
            Thank you for signing up to Bevz! <br />
            <br />
            <div className="text-sm text-center text-gray-500 font-medium">
              You will now be redirected to the Retailer Web Portal to review
              your account details and activate delivery services.
            </div>
          </div>
          <div className="flex flex-col items-center">
            <a
              className="block bg-primary p-2 text-white text-center rounded hover:bg-secondary transition duration-300 w-1/3 disabled:bg-gray-400"
              href="https://retailer.bevz.com/"
            >
              LOGIN
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
