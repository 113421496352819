import { axiosPrivate } from "./axios";

const getStoreDetails = async ({ integratorId, storeId, token }) =>
  await axiosPrivate
    .get(`/integrator-service/integrators/${integratorId}/stores/${storeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data }) => data?.data?.[0]);

export default getStoreDetails;
