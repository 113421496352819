import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import useStore from "../../../../hooks/useStore";
import Error from "../../../../pages/Error";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { getUbereatsStores } from "../../../apiRepository/Ubereats";

export async function loader({ request }) {
  const url = new URL(request.url);
  const code = url.searchParams.get("code");
  const provisionType = url.searchParams.get("provision_type");

  if (!code || !provisionType) {
    throw new Response("", {
      status: 404,
      statusText: "Page not found.",
    });
  }

  // sessionStorage.setItem("ubereatsCode", code);
  // sessionStorage.setItem("ubereatsProvisionType", provisionType);

  return { code, provisionType };
}

export default function UberEatsProvisioning() {
  const { code, provisionType } = useLoaderData();
  const { setStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const storeId = sessionStorage.getItem("storeId");
  const navigate = useNavigate();

  useEffect(() => {
    const postCode = async () => {
      try {
        const ubereats = await getUbereatsStores({ code, storeId });
        console.log(ubereats?.accessToken);
        console.log(ubereats?.stores);

        sessionStorage.setItem("ubereatsCode", code);
        sessionStorage.setItem("ubereatsProvisionType", provisionType);
        sessionStorage.setItem("ubereatsAccessToken", ubereats?.accessToken);
        sessionStorage.setItem(
          "ubereatsStores",
          JSON.stringify(ubereats?.stores),
        );

        setStore({
          ubereatsAccessToken: ubereats?.accessToken,
          ubereatsStores: ubereats?.stores,
          ubereatsCode: code,
          ubereatsProvisionType: provisionType,
        });

        navigate(
          `/ubereats/${provisionType === "add" ? "provision" : "deprovision"}`,
          {
            replace: true,
          },
        );
      } catch (err) {
        navigate(
          `/ubereats/${provisionType === "add" ? "provision" : "deprovision"}}`,
          {
            replace: true,
          },
        );
      } finally {
        setIsLoading(false);
      }
    };

    code && storeId && postCode();
  }, [code, storeId, navigate, provisionType, setStore]);

  if (!storeId) {
    return <Error />;
  }

  if (isLoading)
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <CircularProgress disableShrink />
      </div>
    );

  return <Outlet />;
}
