const configs = {
  api: {
    baseUrl: process.env.REACT_APP_SERVER_URL,
  },
  url: {
    welcomeUrl: process.env.REACT_APP_WELCOME_URL,
    createChildStoreRedirect: process.env.REACT_APP_JOIN_URL,
  },
};
export default configs;
