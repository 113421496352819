export default function PageHeader({ title, subTitle }) {
  return (
    <>
      <h1 className="rem-margin mt-4 mb-0 text-2xl font-semibold text-primaryDark">
        {title}
      </h1>
      {subTitle ? (
        <p className="text-label2 mb-4 text-base">{subTitle}</p>
      ) : null}
    </>
  );
}
