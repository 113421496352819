import { format, isValid } from "date-fns";

const formatDate = (_date) => {
  try {
    const parseDate = new Date(_date);
    if (isValid(parseDate)) {
      return format(parseDate, "yyyy-MM-dd");
    }
  } catch (error) {
    console.error(error);
  }

  return "";
};

export default formatDate;
