import { axiosPrivate } from "./axios";

const getSensitiveData = async ({ integratorId, storeId, token }) =>
  await axiosPrivate
    .get(
      `/integrator-service/integrators/${integratorId}/stores/${storeId}/sensitive-data`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(({ data }) => data?.data);
export default getSensitiveData;
