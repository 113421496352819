const Button = ({
  type = "button",
  onClick,
  label = "C O N F I R M",
  loading = false,
  loadingLabel = "PLEASE WAIT",
  width,
  margin,
  color,
  fontSize,
  disabled,
  rounded,
  ...props
}) => {
  const widths = {
    48: "w-32 md:w-48",
    64: "w-64",
    auto: "w-auto",
  };
  const margins = {
    mdmr6: "md:mr-6",
    mr6: "mr-6",
  };

  const colors = {
    red: "border-red-500 bg-red-500 shadow-red-500/50",
    blue: "border-blue-700 bg-blue-700 shadow-blue-500/50",
    green: "bg-green-700 border-green-700 shadow-green-500/50",
    green600: "bg-green-600 border-green-600 shadow-green-500/50",
    amber: "bg-amber-500 border-amber-500",
  };

  const fontSizes = {
    xl: "text-xl",
    lg: "text-lg",
  };

  return (
    <button
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
      className={`border ${
        colors?.[color]
      } rounded p-3 font-bold text-white shadow-lg disabled:cursor-not-allowed  disabled:border-gray-600 disabled:bg-gray-600 disabled:text-gray-500 disabled:shadow-gray-600/50 ${
        margins?.[margin] || ""
      } ${widths?.[width]} ${fontSizes?.[fontSize] || "text-base"}`}
    >
      {loading ? loadingLabel : label}
    </button>
  );
};

export { Button as default, Button };
