export const validatePhoneNumber = (req) => {
  const phoneUtil =
    require("google-libphonenumber").PhoneNumberUtil.getInstance();

  let num = {
    isValid: false,
    error: "Please input valid US number.",
  };
  let number;

  if (req.length < 4) {
    return (num.isValid = true);
  }

  try {
    number = phoneUtil.parseAndKeepRawInput(req, "US");
  } catch (e) {
    return num;
  }

  num.formatted = phoneUtil.formatInOriginalFormat(number, "US");
  if (phoneUtil.isValidNumberForRegion(number, "US")) {
    num.isValid = true;
    num.error = "";
  }

  return num;
};
