import { useState } from "react";
import deliveryServiceTypes from "../../../constants/deliveryServiceTypes";
import useStore from "../../../hooks/useStore";
import Button from "../Buttons";
import DeliverySettings from "../DeliverySettings";
import GrubHubNotice from "./GrubHubNotice";
import GrubHubOnBoardingForm from "./GrubHubOnBoardingForm";

export default function GrubHubDS() {
  const { store } = useStore();
  const [showForm, setShowForm] = useState(false);
  const deliverySetting = store?.deliverySettings?.grubhub;
  const [showOptions, setShowOptions] = useState(false);
  const [showNotice, setShowNotice] = useState(false);

  const handleBack = () => {
    setShowNotice(false);
    setShowOptions(false);
    setShowForm(false);
  };

  // const [isOnboardingFormSubmitted, setIsOnboardingFormSubmitted] = useState(
  //   store?.onboardingFormSubmitted?.GRUBHUB || false,
  // );

  const handleShowForm = () => {
    setShowForm(true);
    setShowOptions(false);
    setShowNotice(false);
  };

  const handleShowNotice = () => {
    setShowNotice(true);
    setShowOptions(false);
    setShowForm(false);
  };

  // useEffect(() => {
  //   try {
  //     const onboarded = JSON.parse(localStorage.getItem(store.storeId));
  //     console.log(onboarded);
  //     onboarded?.GRUBHUB &&
  //       setIsOnboardingFormSubmitted(
  //         store?.onboardingFormSubmitted?.GRUBHUB || onboarded?.GRUBHUB,
  //       );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [store.storeId, store?.onboardingFormSubmitted?.GRUBHUB]);

  if (
    (!deliverySetting || Object.keys(deliverySetting).length === 0) &&
    !showForm &&
    !showOptions &&
    !showNotice
  ) {
    return (
      <div className="flex h-[150px] items-center justify-center">
        <Button
          label="Enable Delivery Services"
          onClick={handleShowNotice}
          color="green"
        />
      </div>
    );
  }

  if (
    (!deliverySetting || Object.keys(deliverySetting).length === 0) &&
    !showForm &&
    showOptions
  ) {
    return (
      <div className="p-4 text-lg">
        <p className="pb-10">
          If you already use/have an Grub Hub account for your store and want to
          accept/deny orders in Bevz Retail App, please select{" "}
          <span className="font-bold">"Already have an account"</span>.
          <br />
          <br />
          If you don't have an existing account with Grub Hub, and would like to
          Sign Up with them, please select{" "}
          <span className="font-bold">"Don't have an account"</span>. This will
          take you to Grub Hub Sign Up page and after signing up, go back here
          again and select{" "}
          <span className="font-bold">"Already have an account"</span> to login
          to your Grub Hub account.
        </p>
        <div className="flex flex-col gap-4 md:flex-row md:justify-center md:gap-10">
          <Button
            label="DON'T HAVE AN ACCOUNT"
            onClick={handleShowNotice}
            color="amber"
          />
          <Button
            label="ALREADY HAVE AN ACCOUNT"
            onClick={handleShowForm}
            color="green"
          />
        </div>
      </div>
    );
  }

  if (
    (!deliverySetting || Object.keys(deliverySetting).length === 0) &&
    showForm &&
    !showOptions
  ) {
    return <GrubHubOnBoardingForm closeForm={handleBack} />;
  }

  if (
    (!deliverySetting || Object.keys(deliverySetting).length === 0) &&
    !showForm &&
    !showOptions &&
    showNotice
  ) {
    return <GrubHubNotice handleBack={handleBack} />;
  }

  return (
    <DeliverySettings deliveryService={deliveryServiceTypes.grubhub.cammel} />
  );
}
