import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useStore from "../../hooks/useStore";

export default function Index(props) {
  let location = useLocation();
  const navigate = useNavigate();
  const { setOwner } = useStore();

  return (
    <div className=" flex min-h-screen flex-col items-center justify-center bg-bevz-bg bg-cover">
      <div className=" min-h-[44rem] w-[20rem] rounded-xl bg-white p-6 shadow-2xl sm:min-h-[42rem] sm:w-[32rem] sm:p-8">
        <Outlet />
        {/* {location.pathname === "/account" ? (
          <div className="absolute right-8 bottom-8">
            <button
              onClick={handleLogout}
              className="block rounded border border-[#32436D] py-2 px-6 text-[#32436D] transition duration-300 hover:border-secondary hover:bg-secondary hover:text-white disabled:bg-gray-400"
              type="button"
            >
              Log Out
            </button>
          </div>
        ) : null} */}
      </div>
    </div>
  );
}
