import React from "react";
import Error from "../assets/images/error.png";

function ErrorIntegratorAuth() {
  return (
    <div className="mt-24 space-y-8">
      <div className="flex flex-col items-center">
        <img className="h-20 w-20" src={Error} alt=""></img>
      </div>

      <div className="text-center text-xl font-bold text-secondary">
        Access Denied
        <br />
        <br />
        <div className="text-center text-sm font-medium text-gray-500">
          Integrator Id is invalid, please check your input.
        </div>
      </div>
    </div>
  );
}

export default ErrorIntegratorAuth;
