import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaStore } from "react-icons/fa6";
import { useLoaderData, useNavigate } from "react-router-dom";
import useStore from "../../../../hooks/useStore";
import { postProvisionStore } from "../../../apiRepository/Ubereats";
import getStoreDetails from "../../../apiRepository/getStoreDetails";
import Button from "../../Buttons";

export async function loader({ request, params }) {
  const ubereatsProvisionType = sessionStorage.getItem("ubereatsProvisionType");
  const ubereatsAccessToken = sessionStorage.getItem("ubereatsAccessToken");
  const ubereatsStores = JSON.parse(sessionStorage.getItem("ubereatsStores"));
  const integratorId = sessionStorage.getItem("integratorId");
  const storeId = sessionStorage.getItem("storeId");

  if (!ubereatsProvisionType || !ubereatsAccessToken) {
    throw new Response("", {
      status: 404,
      statusText: "Page not found.",
    });
  }

  return {
    ubereatsAccessToken,
    ubereatsProvisionType,
    ubereatsStores,
    integratorId,
    storeId,
    action: params?.action,
  };
}

export default function StoreProvision() {
  const { store } = useStore();
  const navigate = useNavigate();
  const [isPageLoading, setPageIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { setStore } = useStore();
  const { ubereatsAccessToken, ubereatsStores, integratorId, storeId, action } =
    useLoaderData();
  const [snackbar, setSnackbar] = useState({
    severity: "error",
    message: "",
    open: false,
  });

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleProvision = async (ubereatsStoreId) => {
    setIsLoading(true);
    try {
      await postProvisionStore({
        accessToken: ubereatsAccessToken,
        ubereatsStoreId,
        storeId,
        action,
      });
      setSnackbar({
        severity: "success",
        message:
          action === "provision" ? "Onboarding Success" : "Deprovision Success",
        open: true,
        onClose: () => navigate("/pos/delivery-services/ubereats"),
      });
    } catch (err) {
      const { errors } = err?.response?.data;
      console.error(errors);
      setSnackbar({
        severity: "error",
        message: errors.map((error, i) => <p key={i}>{error}</p>),
        open: true,
        onClose: handleCloseSnackbar,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getStore = async () => {
      try {
        const storeDetails = await getStoreDetails({
          integratorId,
          storeId,
          token: store?.token,
        });

        setStore((prev) => ({ ...prev, ...storeDetails, ubereatsAccessToken }));
      } catch (err) {
        console.error(err?.response || err);
        console.error(JSON.stringify(err));
      } finally {
        setPageIsLoading(false);
      }
    };

    getStore();
    // postCode();
  }, [storeId, integratorId, setStore, ubereatsAccessToken]);

  if (isPageLoading)
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <CircularProgress disableShrink />
      </div>
    );

  return (
    <>
      <div className="container mx-auto">
        <div className="mb-4 p-4">
          <p className="mb-4 text-xl font-bold">SELECT UBEREATS STORE</p>
          {!ubereatsStores || ubereatsStores?.length <= 0 ? (
            <p className="font-bold">No record found.</p>
          ) : null}
          {(ubereatsStores || []).map((store) => (
            <div
              key={store.store_id}
              className=" flex items-center rounded bg-slate-200 p-2 py-4"
            >
              <div className="flex-1">
                <p className="m-0 font-bold">
                  <FaStore className="inline-block text-gray-400" />
                  <span className="pl-1 text-lg">{store?.name}</span>
                </p>
                <p className="m-0">
                  <FaMapMarkerAlt className="inline-block text-gray-400" />{" "}
                  {store?.location?.address}
                </p>
              </div>
              <div>
                <Button
                  width={"auto"}
                  color={"green"}
                  loading={isLoading}
                  label={`${
                    action === "provision" ? "PROVISION" : "DEPROVISION"
                  }`}
                  onClick={handleProvision.bind(this, store.store_id)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={snackbar.onClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant="filled"
          onClose={snackbar.onClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
