import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import { GRUBHUB_SIGNUP_PAGE } from "../../../constants/grubhub";
import useStore from "../../../hooks/useStore";
import Button from "../Buttons";
import postOnboardForm from "../../apiRepository/postOnboardForm";

export default function GrubHubNotice({ handleBack }) {
  const { store } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [snackbar, setSnackbar] = useState({
    severity: "error",
    message: "",
    open: false,
    closeHandler: () => {},
    variant: "standard",
  });

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
    setIsLoading(false);
  };

  const handleCloseSnackbar2 = () => {
    handleCloseSnackbar();
    handleBack();
  };

  const submit = async () => {
    setIsLoading(true);

    await postOnboardForm({
      headers: {
        Authorization: `Bearer ${
          store?.token || sessionStorage.getItem("token")
        }`,
      },
      integratorId: store?.integratorId,
      storeId: store?.storeId,
      deliveryService: "GRUBHUB",
      isExisting: false,
    })
      .then(() => {
        const url = new URL(GRUBHUB_SIGNUP_PAGE);
        const {
          name,
          storeId,
          address: { street1, city, zipCode, state },
          phone,
          email,
          salesTax,
          taxRate,
          formData: {
            contactFirstName = "",
            contactLastName = "",
            legalBusinessName = "",
          } = {},
        } = store;

        url.searchParams.append("locationName", name);
        url.searchParams.append("merchantId", storeId);
        url.searchParams.append("locationAddress[addr_line1]", street1);
        url.searchParams.append("locationAddress[city]", city);
        url.searchParams.append("locationAddress[postal]", zipCode);
        url.searchParams.append("locationAddress[state]", state);
        url.searchParams.append("locationAddress[country]", "United States");
        url.searchParams.append("primaryContact68[first]", contactFirstName);
        url.searchParams.append("primaryContact68[last]", contactLastName);
        url.searchParams.append("kitchenPhone77", phone);
        url.searchParams.append("primaryContact76", phone);
        url.searchParams.append("primaryContact62", email);
        url.searchParams.append("salesTax", salesTax || taxRate);
        url.searchParams.append("signeeFull[first]", contactFirstName);
        url.searchParams.append("signeeFull[last]", contactLastName);
        url.searchParams.append("legalCorporate", legalBusinessName);
        url.searchParams.append("corporateEntity[addr_line1]", street1);
        url.searchParams.append("corporateEntity[city]", city);
        url.searchParams.append("corporateEntity[state]", state);
        url.searchParams.append("corporateEntity[postal]", zipCode);
        url.searchParams.append("signeeFull85[first]", contactFirstName);
        url.searchParams.append("signeeFull85[last]", contactLastName);

        window.open(url);
        handleCloseSnackbar2();
      })
      .catch((err) => {
        console.log(err);
        setSnackbar({
          severity: "error",
          message: err.message,
          open: true,
          variant: "standard",
          closeHandler: handleCloseSnackbar,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="p-4">
        <h1 className="text-2xl">GrubHub Onboarding</h1>
        <p className="mb-0 text-justify text-lg">
          To sign up with GrubHub fill out the form, you will need to have your
          Liquor License and W-9 to complete this process.
          <br />
          <br />
          Page 1 - Liquor License... select "Browse Files" and tap Take Photo to
          attach a picture of your Liquor License. Make sure that all 4 corners
          are visible.
          <br /> <br />
          Page 2 - W9 Documentation... select "Browse Files" and tap Take Photo
          to attach a picture of your W9. Make sure that all info is visible,
          including the signature in Part II
          <br />
          <br />
        </p>
        <div className="mb-4 flex">
          <input
            id="checkProceed"
            type="checkbox"
            className=" mr-4 h-6 w-6 accent-red-500"
            value={check}
            onClick={() => setCheck((prev) => !prev)}
          />
          <label htmlFor="checkProceed" className="mb-0 text-lg text-red-500">
            Please indicate by checking the box if you comprehend and would like
            to proceed with the form
          </label>
        </div>
        <div className="text-right">
          <Button
            label="Proceed"
            color="green"
            width={48}
            margin={"mr6"}
            disabled={!check || isLoading}
            onClick={submit}
            loading={isLoading}
            loadingLabel="Please Wait..."
          />
          <Button label="Cancel" color="red" width={48} onClick={handleBack} />
        </div>
      </div>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={snackbar.closeHandler}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant={snackbar.variant}
          onClose={snackbar.closeHandler}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
