import axios from "axios";
import configs from "../configs";

const header = {
  "Content-Type": "application/json",
  "Access-Control-Request-Method": "POST",
};

const instance = axios.create({
  baseURL: configs.api.baseUrl,
  //timeout: 20000,
});

export const checkBankAccountInfo = (req) =>
  instance
    .post("/check-bank-information", {
      headers: header,
      body: JSON.stringify(req),
    })
    .then((res) => {
      if (res.data.errorMessage) {
        throw new Error(res.data.errorMessage);
      }
      return res.data;
    });

export const createStore = (body) =>
  instance.post("/create-store", body, { header }).then((res) => {
    if (res.data.errorMessage) {
      throw new Error(res.data.errorMessage);
    }
    return res.data;
  });

export const validatePromotionCode = (body) =>
  instance.post("/validate-promo-code", body, { header }).then((res) => {
    if (res.data.errorMessage) {
      throw new Error(res.data.errorMessage);
    }
    return res.data;
  });
