import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Transition from "../Transition";
import Button from "../Buttons";

export default function DoordashDialogConfirm({
  isSubmitting,
  showModal,
  handleCloseModal,
  handleConfirm,
  errors,
  clearError,
}) {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "0px",
        },
      }}
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseModal}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>CONFIRM</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to submit data?
          {errors ? (
            <Alert severity="error" onClose={clearError}>
              <AlertTitle>Failed</AlertTitle>
              {errors.map((error) => (
                <p className="mb-0 capitalize">{error}</p>
              ))}
            </Alert>
          ) : null}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ pb: "1.5rem", px: "1.5rem" }}>
        <Button
          loading={isSubmitting}
          type="submit"
          width={48}
          margin={"mdmr6"}
          color="green"
          onClick={handleConfirm}
        />

        <Button
          loading={isSubmitting}
          loadingLabel="C A N C E L"
          label="C A N C E L"
          width={48}
          color="red"
          onClick={handleCloseModal}
        />
      </DialogActions>
    </Dialog>
  );
}
