import { MdAccountCircle } from "react-icons/md";
import { RiStoreFill } from "react-icons/ri";
import { HiMiniClock } from "react-icons/hi2";

export default function CreateStoreInfo(props) {
  return (
    <div className="grid-row-3 grid divide-y">
      <div className="pb-4">
        <div className="mb-0 flex w-full flex-row items-center  text-gray-800">
          <MdAccountCircle size={25} />
          <span className="ml-2 text-lg font-medium leading-none">
            Account Information
          </span>
        </div>
        <div className="mt-2 grid sm:grid-cols-2">
          <div className="text-gray-500">
            {props.formData.account.emailAddress ? "Email Address" : "Username"}
          </div>
          <div className="text-right">
            {props.formData.account.emailAddress ||
              props.formData.account.username}
          </div>
        </div>
      </div>
      <div className="py-4">
        <div className="mb-0 flex w-full flex-row items-center  text-gray-800">
          <RiStoreFill size={25} />
          <span className="ml-2 text-lg font-medium leading-none">
            Store Information
          </span>
        </div>
        <div className="mt-2 grid sm:grid-cols-2">
          <div className="text-gray-500">Store Name</div>
          <div className="text-right">{props.formData.store.name}</div>
        </div>
        <div className="grid sm:grid-cols-2 ">
          <div className="text-gray-500">Store Number</div>
          <div className="text-right">{props.formData.store.phoneNumber}</div>
        </div>
        <div className="grid sm:grid-cols-2 ">
          <div className="text-gray-500">Store Email Address</div>
          <div className="text-right">{props.formData.store.emailAddress}</div>
        </div>
        <div className="mt-2 grid sm:grid-cols-2">
          <div className="text-gray-500">Store Address</div>
          <div className="sm:grid-row-4 grid">
            <div className="text-right">
              {props.formData.store.address.street1}
            </div>
            <div className="text-right">
              {props.formData.store.address.street2}
            </div>
            <div className="text-right">
              {props.formData.store.address.city},{" "}
              {props.formData.store.address.state},{" "}
              {props.formData.store.address.zipCode}
            </div>
            <div className="text-right">U.S.</div>
          </div>
        </div>
      </div>
      <div className="py-4">
        <div className="mb-2 flex w-full flex-row items-center text-gray-800">
          <HiMiniClock size={25} />
          <span className="ml-2 text-lg font-medium leading-none">
            Store Operation Hours
          </span>
        </div>
        <div>
          {props.formData.operationHours.map((day) => (
            <div className="grid sm:grid-cols-2 ">
              <div className="text-gray-500">{day.day}</div>
              <div className="text-right">
                {day.startTime} - {day.endTime}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
