const deliveryServiceTypes = Object.freeze({
  bevz: { capitalize: "BEVZ", cammel: "bevz" },
  doordash: { capitalize: "DOORDASH", cammel: "doordash" },
  grubhub: { capitalize: "GRUBHUB", cammel: "grubhub" },
  uberEats: { capitalize: "UBEREATS", cammel: "uberEats" },
});

export default deliveryServiceTypes;

// DOORDASH | UBEREATS | GRUBHUB | BEVZ
// bevz | doordash | grubhub | uberEats
