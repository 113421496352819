import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingSVG from "../components/multistore/LoadingSVG";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import PageHeader from "../components/multistore/PageHeader";
import { postResetPassword } from "../components/apiRepository/DynamodbRepository";
import { GoCheckCircle } from "react-icons/go";

export function loader({ params }) {
  const userId = params?.userId || false;
  const token = params?.token || false;

  if (!userId || !token) {
    redirect("/404");
  }
  return { userId, token };
}

export default function ResetPassword() {
  const { userId, token } = useLoaderData();
  const [apiError, setApiError] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({});
  const password = useRef({});
  const [success, setSuccess] = useState(false);
  password.current = watch("password", "");

  const handleBack = (replace = false) => navigate("/login", { replace });

  const onSubmit = async (data) => {
    setApiError(null);
    await postResetPassword({
      password: data.password,
      id: userId,
      token,
    })
      .then(() => {
        setSuccess(true);
      })
      .catch((err) => {
        let errors = err.response.data?.errors || [];

        if (errors.find((elem) => elem === "Invalid token!")) {
          errors = [
            "Your token has expired. Please request to reset your password again.",
          ];
        }
        setApiError(errors);
      });
  };

  return !success ? (
    <div>
      <div>
        <div
          className="flex w-fit cursor-pointer items-center"
          onClick={handleBack}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2 h-5 w-5 text-primary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          <span className="rem-margin text-sm text-secondary">Cancel</span>
        </div>
      </div>
      <PageHeader
        title={"Reset Password"}
        subTitle={success ? null : "Please enter your new password"}
      />
      <form
        id="account-details-form"
        className="text-sm text-gray-800"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-2 mt-5 space-y-2">
          <div>
            <label className="block text-xs font-bold text-label2">
              New Password <span className="text-secondary">*</span>
            </label>
            <input
              className="focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
              type="password"
              {...register("password", {
                required: "New password is required.",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters.",
                },
                onChange: () => trigger("password"),
              })}
            />
            {errors?.password ? (
              <span className="ml-1 text-xs text-red-500">
                {errors?.password?.message}
              </span>
            ) : null}
          </div>
          <div>
            <label className="block text-xs font-bold text-label2">
              Confirm New Password <span className="text-secondary">*</span>
            </label>
            <input
              className="focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
              type="password"
              {...register("confirmPassword", {
                required: "Confirm new password is required.",
                validate: (value) =>
                  value === password.current || "Password does not match!",
                onChange: () => trigger(["password", "confirmPassword"]),
              })}
            />
            {errors?.confirmPassword ? (
              <span className="ml-1 text-xs text-red-500">
                {errors?.confirmPassword?.message}
              </span>
            ) : null}
          </div>
        </div>

        {(apiError || []).map((error) => (
          <div className="text-red-500">{error}</div>
        ))}

        <div className="mt-4 text-right">
          <button
            className=" w-1/3 rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? <LoadingSVG /> : "Reset Password"}
          </button>
        </div>
      </form>{" "}
    </div>
  ) : (
    <Success handleBack={handleBack} />
  );
}

function Success({ handleBack }) {
  return (
    <div className="flex flex-col items-center">
      <div className="mb-4">
        <GoCheckCircle size={40} color="green" />
      </div>

      <h1 className="mb-4 text-xl font-bold">Password Changed!</h1>
      <p className="mb-8">Your password has been changed successfully.</p>

      <button
        className="w-1/3 rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
        type="submit"
        onClick={() => handleBack(true)}
      >
        Log In Now
      </button>
    </div>
  );
}
