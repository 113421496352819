import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import deliveryServiceTypes from "../../../constants/deliveryServiceTypes";
import useStore from "../../../hooks/useStore";
import Button from "../Buttons";
import DeliverySettings from "../DeliverySettings";
import Transition from "../Transition";
import OnboardingForm from "./OnboardingForm";

export default function UberEatsDS() {
  const { store } = useStore();
  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const deliverySetting = store?.deliverySettings?.uberEats;
  const [hasExistingAccount, setHasExistingAccount] = useState(false);
  const [snackbar, setSnackbar] = useState({
    severity: "error",
    message: "",
    open: false,
  });

  const handleHasExisting = (has) => {
    setHasExistingAccount(has);
    setShowForm(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    setSnackbar((prev) => ({ ...prev, open: false, message: null }));
  };

  const handleProvision = (action) => {
    try {
      const url = `${process.env.REACT_APP_UBEREATS_PROVISIONING_LINK}?response_type=code&client_id=${process.env.REACT_APP_UBEREATS_CLIENTID}&scope=eats.pos_provisioning&redirect_uri=${process.env.REACT_APP_UBER_REDIRECT_URI}?provision_type=${action}`;

      console.log(url);
      window.open(url, "_self");
      // https://retailer.bevz.com/ubereats?code=crd.EA.CAESELAsOwHmPUcQmx7G60M_hKIiATE.l7L83yR0whaLzpvwqlr1_l1P3PqGEkp2wKoUYMW9eb0&provision_type=add#_
    } catch (error) {
      console.error(error);
      setSnackbar({ open: true, message: error, severity: "error" });
    }
  };

  if (
    (!deliverySetting || Object.keys(deliverySetting).length === 0) &&
    !showForm
  ) {
    return (
      <>
        <div className="p-4 text-lg">
          <p className="pb-10">
            If you already use/have an Uber Eats account for your store and want
            to accept/deny orders in Bevz Retail App on the iPad, please select{" "}
            <span className="font-bold">"Already have an account"</span>.
            <br />
            <br />
            If you don't have an existing account with Uber Eats, and would like
            to Sign Up with them, please select{" "}
            <span className="font-bold">"Don't have an account"</span>. This
            will take you to Uber Eats Sign Up page and after signing up, go
            back here again and select{" "}
            <span className="font-bold">"Already have an account"</span> to
            login to your Uber Eats account.
          </p>
          <div className="flex flex-col gap-4 md:flex-row md:justify-center md:gap-10">
            <Button
              label="DON'T HAVE AN ACCOUNT"
              onClick={handleHasExisting.bind(this, false)}
              color="amber"
            />
            <Button
              label="ALREADY HAVE AN ACCOUNT"
              onClick={handleProvision.bind(this, "add")}
              color="green"
            />
          </div>
        </div>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </>
    );
  }

  if (
    (!deliverySetting || Object.keys(deliverySetting).length === 0) &&
    showForm
  ) {
    return (
      <OnboardingForm
        closeForm={() => setShowForm(false)}
        deliveryService={deliveryServiceTypes.uberEats.capitalize}
        hasExistingAccount={hasExistingAccount}
      />
    );
  }

  return (
    <>
      <div className="p-4 text-right">
        <Button
          label="DEPROVISION"
          onClick={() => setShowModal(true)}
          color="red"
        />
      </div>
      <DeliverySettings
        deliveryService={deliveryServiceTypes.uberEats.cammel}
      />
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowModal(false)}
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>WARNING</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to remove your integration with Uber Eats. Do you want
            to proceed?
            <br /> <br />
            Removing integration with Bevz will stop your Uber Merchant store
            from working on ubereats.com
            <br /> <br />
            To ensure seamless provisioning/deprovision of store, you will need
            to logout from your{" "}
            <Link
              rel="noreferrer"
              href="https://riders.uber.com/v2/profile"
              target="_blank"
            >
              https://riders.uber.com/v2/profile
            </Link>{" "}
            account before proceeding to this request. account
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb: "1.5rem", px: "1.5rem" }}>
          <Button
            type="button"
            width={48}
            margin={"mdmr6"}
            label="P R O C E E D"
            color="green"
            onClick={handleProvision.bind(this, "remove")}
          />

          <Button
            loadingLabel="C A N C E L"
            label="C A N C E L"
            width={48}
            color="red"
            onClick={() => setShowModal(false)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
