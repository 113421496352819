const disableStoreReasons = [
  { value: "out_of_business", name: "Out of Business" },
  { value: "operational_issues", name: "Operational Issues" },
  { value: "delete_store", name: "Delete Store" },
  { value: "payment_issue", name: "Payment Issue" },
  {
    value: "store_self_disabled_in_their_POS_portal",
    name: "Store Self Disabled In Their POS Portal",
  },
  {
    value: "store_pos_connectivity_issues",
    name: "Store POS Connectivity Issues",
  },
];

const disableStoreReasonsObject = {
  out_of_business: "Out of Business",
  operational_issues: "Operational Issues",
  delete_store: "Delete Store",
  payment_issue: "Payment Issue",
  store_self_disabled_in_their_POS_portal:
    "Store Self Disabled In Their POS Portal",
  store_pos_connectivity_issues: "Store POS Connectivity Issues",
};

export { disableStoreReasons as default, disableStoreReasonsObject };
