import { useMemo, useState } from "react";
import { createStore } from "../../components/apiRepository/StripeRepository";
import LoadingSVG from "../../components/multistore/LoadingSVG";
import configs from "../../components/configs";
import useStore from "../../hooks/useStore";
import pricingTier from "../../constants/pricingTier";
import { verifyIntegratorId } from "../../helpers/integratorId";
import { isValidUrl } from "../../helpers/isValidUrl";
import CreateStoreInfo from "../../components/CreateStoreInfo";

export default function Confirmation({ formData }) {
  const { owner } = useStore();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const integrator_id = sessionStorage.getItem("integrator_id");
  //const integrator_callback = sessionStorage.getItem("integrator_callback");
  //const integrator_auth = sessionStorage.getItem("integrator_auth");

  let callbackJoin = `${configs.url.createChildStoreRedirect}`;
  callbackJoin +=
    integrator_id !== "null"
      ? `/pos_onboarding?integrator_id=${integrator_id}`
      : "/";

  const isExempted = useMemo(() => verifyIntegratorId(), [integrator_id]);

  const subscribe = async () => {
    if (loading) {
      return;
    }
    setErr(null);
    setLoading(true);

    try {
      await createStore({
        ...formData,
      });
      window.location.replace(callbackJoin);
    } catch (e) {
      if (e.response.data.status === 400) {
        setErr(e.response.data.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  const pricing = () => {
    const storeCount = owner?.activeStoreCount || 0;

    if (storeCount <= 3) return pricingTier[1].amount;
    if (storeCount >= 4 && storeCount <= 13) return pricingTier[2].amount;
    if (storeCount >= 14) return pricingTier[3].amount;
  };

  return (
    <div>
      {err ? (
        <div className="flex items-center justify-between">
          <div>
            <p>
              There was an error processing your request. Please go back and
              ensure all details provided are complete and correct.
            </p>
            <div>Error/s:</div>
            <div className="p-4">
              <ul className="list-disc pt-0">
                {err.map((e) => (
                  <li>{e}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      {isExempted ? (
        <CreateStoreInfo formData={formData} />
      ) : (
        <p className="text-xs text-form">
          An additional ${pricing()} per month will be charged to your current
          payment method on your next billing cycle.
        </p>
      )}

      <p className="mt-6 text-center">
        <button
          id="begin-sign-up-btn"
          className="w-full rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
          type="button"
          onClick={subscribe}
        >
          {loading ? <LoadingSVG /> : "Confirm New Store"}
        </button>
      </p>
    </div>
  );
}
