import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Transition from "../Transition";
import Button from "../Buttons";

export default function DialogConfirm({
  isSubmitting,
  showModal,
  handleCloseModal,
  handleConfirm,
  errors,
  clearError,
}) {
  return (
    <Dialog
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseModal}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>CONFIRM</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to submit data?
        </DialogContentText>
        {errors ? (
          <Alert severity="error" onClose={clearError}>
            <AlertTitle>Failed</AlertTitle>
            {Array.isArray(errors)
              ? errors.map((error, i) => (
                  <p key={i} className="mb-0 capitalize">
                    {error}
                  </p>
                ))
              : errors}
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ pb: "1.5rem", px: "1.5rem" }}>
        <Button
          loading={isSubmitting}
          type="submit"
          width={48}
          margin={"mdmr6"}
          color="green"
          onClick={handleConfirm}
        />

        <Button
          loading={isSubmitting}
          loadingLabel="C A N C E L"
          label="C A N C E L"
          width={48}
          color="red"
          onClick={handleCloseModal}
        />
      </DialogActions>
    </Dialog>
  );
}
