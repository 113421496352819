const pricingTier = {
  1: {
    amount: "99",
    nextTier: "5-14",
    nextTierAmount: "79",
  },
  2: {
    amount: "79",
    nextTier: "15+",
    nextTierAmount: "59",
  },
  3: {
    amount: "59",
  },
};

export default pricingTier;
