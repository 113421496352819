import React, { useEffect, useState } from "react";
import Bevz from "../assets/images/bevz-v1.png";
import { useNavigate, useLoaderData } from "react-router-dom";
import axios from "axios";
import ErrorIntegratorAuth from "../../src/components/ErrorIntegratorAuth";

export async function loader({ request }) {
  const url = new URL(request.url);
  const integrator_id = url.searchParams.get("integrator_id");
  sessionStorage.setItem("integrator_id", integrator_id);
  // const integrator_auth = url.searchParams.get("auth_token");
  // sessionStorage.setItem("integrator_auth", integrator_auth);
  // const integrator_callback = url.searchParams.get("callback_url");
  // sessionStorage.setItem("integrator_callback", integrator_callback);

  return { integrator_id };
}

function WelcomePage(props) {
  const navigate = useNavigate();

  const handleCreateAccount = () => {
    props?.setStep ? props.setStep((step) => step + 1) : navigate("/signup");
  };

  const [err, setErr] = useState(false);
  const response = useLoaderData();

  useEffect(() => {
    if (response?.integrator_id) {
      if (response?.integrator_id === "240208002") {
        // bottle pos
        sessionStorage.setItem("integrator_id", response?.integrator_id);
      } else {
        setErr(true);
      }
    }
  }, []);

  return err ? (
    <ErrorIntegratorAuth />
  ) : (
    <div className="mt-16 space-y-8">
      <div className="flex flex-col items-center">
        <img
          src="https://retailer.bevz.com/img/bevz_logo.png"
          className="w-18 h-24"
          alt=""
        ></img>
      </div>

      <div className="text-center text-xl font-bold text-secondary">
        Welcome to Bevz!
        <br />
        <br />
        <div className="text-center text-sm font-medium text-gray-500">
          We can't wait to get you started. Please fill out all fields in this
          form to enroll your store with Bevz. For any questions, contact us at
          &nbsp;
          <a
            className="text-secondary"
            href="mailto:support@bevz.com?subject=Bevz Support"
          >
            support@bevz.com
          </a>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <p className="mb-3 font-bold text-[#495057]">Are you new to Bevz?</p>
        <button
          id="begin-sign-up-btn"
          className="block rounded bg-primary p-2 leading-tight text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400 md:w-1/3"
          type="submit"
          onClick={handleCreateAccount}
        >
          Create Account
        </button>
      </div>

      <div className="flex flex-col items-center">
        <p className="mb-3 text-center font-bold leading-tight text-[#495057]">
          Are you adding new stores to
          <br /> your existing account?
        </p>
        <button
          id="begin-sign-up-btn"
          className="block w-1/3 rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
          type="submit"
          onClick={() => navigate("/login")}
        >
          Log In
        </button>
      </div>
    </div>
  );
}

export default WelcomePage;
