import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  generateCheckoutLink,
  getStoreSubscriptionDetails,
} from "../../components/apiRepository/DynamodbRepository";
import PageHeader from "../../components/multistore/PageHeader";
import StorePricingTier from "../../components/multistore/StorePricingTier";
import pricingTier from "../../constants/pricingTier";
import useStore from "../../hooks/useStore";
import LoadingSVG from "../../components/multistore/LoadingSVG";
import { verifyIntegratorId } from "../../helpers/integratorId";

export default function StoreList() {
  const { setOwner, owner } = useStore();
  const [activeStores, setActiveStores] = useState([]);
  const [invoice, setInvoice] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const integrator_id = sessionStorage.getItem("integrator_id");

  const isExempted = useMemo(() => verifyIntegratorId(), [integrator_id]);

  const handleLogout = () => {
    setOwner({});
    localStorage.removeItem("ownerId");
    localStorage.removeItem("ownerStoreId");
    sessionStorage.removeItem("integrator_id");
    sessionStorage.removeItem("integrator_callback");
    navigate("/login");
  };

  const handleGenCheckoutLink = async () => {
    if (status === "canceled") {
      window.location.href = "https://www.bevz.com/bevzdeliverysupport";
      return false;
    }
    setLoading(true);
    await generateCheckoutLink({ token: owner.token, storeId: owner.storeId })
      .then((url) => {
        if (url) {
          window.location.replace(url);
        }
      })
      .catch((err) => alert(err?.response?.data?.errors?.[0] || err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getStoreSubscription = async () => {
      try {
        const response = await getStoreSubscriptionDetails({
          storeId: owner?.storeId,
          header: {
            signal: controller.signal,
          },
        });

        isMounted && setStatus(response.status);
        isMounted && setActiveStores(response?.activeStores);
        isMounted && setInvoice(response?.invoice);
        setOwner((prev) => ({
          ...prev,
          activeStoreCount: response?.activeStores?.length,
        }));
      } catch (err) {
        setActiveStores([]);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    owner?.token && owner?.storeId && getStoreSubscription();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [owner?.storeId, setOwner, owner?.token]);

  return (
    <div className="relative min-h-[37rem]">
      <PageHeader title={"Your Stores"} />
      {loading ? (
        <p>Getting store and subscription details. Please wait...</p>
      ) : null}

      {!loading ? (
        <>
          <h2 className="mt-4 mb-0 font-bold">Active Stores</h2>
          <div className="max-h-[10.5rem] overflow-y-scroll text-label2">
            {activeStores.map((i) => (
              <p className="mb-0 text-xs" key={i}>
                {i}
              </p>
            ))}
          </div>
          <p className="text-xs font-bold">
            Total Stores: {activeStores?.length}
          </p>
        </>
      ) : null}

      {isExempted && !invoice?.unitAmount && !loading ? (
        <p className="mt-5 mb-5">
          <button
            className="block w-1/3 rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
            type="button"
            onClick={() => navigate("store")}
          >
            Add New Store
          </button>
        </p>
      ) : null}

      {status &&
      !isExempted &&
      invoice?.unitAmount &&
      status !== "inactive" &&
      status !== "canceled" ? (
        <>
          <h2 className="mt-5 mb-0 font-bold">
            Current Pricing Tier: ${invoice?.unitAmount || "-"}/month
          </h2>
          <TierDescription activeStores={activeStores} />
          {/* 
          <p className="mt-2 text-xs font-bold">
            Total Amount:{" "}
            {invoice?.totalAmount ? `$${invoice?.totalAmount}` : null}
          </p>
          {invoice?.discount === "none" ? null : (
            <p className="text-xs italic">
              Discount: ${invoice?.discount} Off Coupon Applied
            </p>
          )} */}

          <p className="mt-5 mb-5">
            <button
              className="block w-1/3 rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
              type="button"
              onClick={() => navigate("store")}
            >
              Add New Store
            </button>
          </p>

          <StorePricingTier />
        </>
      ) : null}

      {status && (status === "inactive" || status === "canceled") ? (
        <Inactive
          handleGenCheckoutLink={handleGenCheckoutLink}
          isLoading={loading}
          status={status}
        />
      ) : null}

      <div className="absolute right-0 bottom-0">
        <button
          onClick={handleLogout}
          className="block rounded border border-[#32436D] py-2 px-6 text-[#32436D] transition duration-300 hover:border-secondary hover:bg-secondary hover:text-white disabled:bg-gray-400"
          type="button"
        >
          Log Out
        </button>
      </div>
    </div>
  );
}

function TierDescription({ activeStores }) {
  const storeCount = activeStores.length;

  const message = (tier) => (
    <>
      <p className="mb-2 text-xs text-label2">
        Congratulations! Adding your next store will move you to the{" "}
        {pricingTier[tier].nextTier} store pricing tier. All of your stores will
        receive the discounted ${pricingTier[tier].nextTierAmount} per month
        rate.
      </p>
      <p className="mb-0 text-xs text-label2">
        You will be charged an additional ${pricingTier[tier].nextTierAmount}
        /month to your current payment method.
      </p>
    </>
  );

  const messageOnTrial = () => (
    <p className="mb-0 text-xs text-label2">
      You will be charged an additional ${pricingTier[1].amount}/month to your
      current payment method after your free trial.
    </p>
  );

  const messageDefault = (tier) => (
    <p className="mb-0 text-xs text-label2">
      You will be charged an additional ${pricingTier[tier].amount}/month to
      your current payment method on your next billing cycle.
    </p>
  );

  if (storeCount === 0) return null;
  if (storeCount === 1) return messageOnTrial();
  if (storeCount <= 3) return messageDefault(1);
  if (storeCount === 4) return message(1);
  if (storeCount > 4 && storeCount <= 13) return messageDefault(2);
  if (storeCount === 14) return message(2);
  if (storeCount >= 15) return messageDefault(3);

  return null;
}

function Inactive({ handleGenCheckoutLink, isLoading, status }) {
  return (
    <>
      {status === "inactive" ? (
        <>
          <h2 className="mt-7 mb-0 font-bold">
            Your subscription is currently inactive
          </h2>
          <p className="mt-4 mb-2  text-label2">
            Please proceed with the checkout link to activate your subscription.
          </p>
        </>
      ) : null}

      {status === "canceled" ? (
        <>
          <p className="mt-8 mb-2 text-label2">
            You do not have an active Bevz subscription.
          </p>
          <p className="mt-4 mb-2 text-label2">
            If you would like to reactivate your subscription please reach out
            to our customer service.
          </p>
        </>
      ) : null}

      <p className="mt-5 mb-5">
        <button
          className="block min-w-[136px] rounded bg-primary py-2 px-4 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
          type="button"
          onClick={handleGenCheckoutLink}
        >
          {isLoading ? (
            <LoadingSVG />
          ) : status === "inactive" ? (
            "Proceed to Checkout"
          ) : status === "canceled" ? (
            "Contact Us"
          ) : (
            ""
          )}
        </button>
      </p>
    </>
  );
}
