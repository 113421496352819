import { Alert, Snackbar } from "@mui/material";
import { format, isFuture, parseISO } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import TYPEOFENTITY from "../../../constants/typeOfEntity";
import formatDate from "../../../helpers/formatDate";
import useStore from "../../../hooks/useStore";
import getSensitiveData from "../../apiRepository/getSensitiveData";
import getStoreDetails from "../../apiRepository/getStoreDetails";
import postOnboardForm from "../../apiRepository/postOnboardForm";
import Button from "../Buttons";
import DoordashDialogConfirm from "../doordash/DoordashDialogConfirm";

export default function GrubHubOnBoardingForm({ closeForm }) {
  const { store, setStore } = useStore();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [apiErrors, setApiErrors] = useState(null);
  const [snackbar, setSnackbar] = useState({
    severity: "error",
    message: "",
    open: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      firstName: store.formData?.contactFirstName,
      lastName: store.formData?.contactLastName,
      isExisting: store.formData?.isExisting,
      salesTax: store?.taxRate || store?.salesTax || store?.formData?.salesTax,
      sensitiveData: {
        legalEmail: store?.formData?.legalEmail || store?.email,
        legalBusinessName: store?.formData?.legalBusinessName,
        legalDOB: formatDate(store?.formData?.legalDOB),
        typeOfEntity: store?.formData?.typeOfEntity,
        bankAccountNumber: store?.formData?.bankAccountNumber,
        routingNumber: store?.formData?.routingNumber,
        ssn: store?.formData?.ssn,
        ein: store?.formData?.ein,
      },
    },
  });

  const handleCloseSnackbar = (event, reason) => {
    setSnackbar((prev) => ({ ...prev, open: false }));
    closeForm();
  };

  const handleCloseModal = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    setShowConfirmModal(false);
    setApiErrors(null);
  };

  const submit = async (data) => {
    try {
      setApiErrors(null);

      data.sensitiveData.legalDOB = format(
        parseISO(data.sensitiveData.legalDOB),
        "MM/dd/yyyy",
      );

      data.sensitiveData.ein = String(data.sensitiveData.ein).replace(/-/g, "");
      delete data.isExisting;

      const res = await postOnboardForm({
        headers: {
          Authorization: `Bearer ${
            store?.token || sessionStorage.getItem("token")
          }`,
        },
        integratorId: store?.integratorId,
        storeId: store?.storeId,
        deliveryService: "GRUBHUB",
        isExisting: true,
        data,
      });

      setFormSuccess(true);
      localStorage.setItem(
        store.storeId,
        JSON.stringify({
          ...store.onboardingFormSubmitted,
          GRUBHUB: true,
        }),
      );

      const storeDetails = await getStoreDetails({
        integratorId: store?.integratorId,
        storeId: store.storeId,
        token: store?.token,
      });
      const formData = await getSensitiveData({
        integratorId: store?.integratorId,
        storeId: store.storeId,
        token: store?.token,
      }).catch((err) => console.error(err));

      setStore((prev) => ({
        ...prev,
        ...storeDetails,
        onboardingFormSubmitted: {
          ...prev.onboardingFormSubmitted,
          GRUBHUB: true,
        },
        formData,
      }));
      handleCloseModal();

      setSnackbar({
        severity: "success",
        message: res?.message || "Successfully submitted.",
        open: true,
      });
    } catch (err) {
      const { errors } = err?.response?.data;
      console.log(err?.response || err);

      errors && setApiErrors(errors);
    }
  };

  const hanldeConfirm = (data) => {
    // sessionStorage.setItem(store.storeId, data?.salesTax);
    // setStore((prev) => ({ ...prev, formData: data }));
    setShowConfirmModal(true);
  };

  return (
    <>
      <div className="p-4">
        <form onSubmit={handleSubmit(hanldeConfirm)}>
          <div className="mb-4 grid auto-rows-auto grid-cols-2 gap-y-2  gap-x-2 md:gap-x-8">
            {/* Store Details */}
            <div>
              <div className="mb-2">
                <label htmlFor="firstName" className="uppercase">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  Legal Owner First Name
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2"
                  type="text"
                  id="firstName"
                  {...register("firstName", {
                    required: "First name is required.",
                  })}
                />
                <Error message={errors?.firstName?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="lastName" className="uppercase">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  Legal Owner Last Name
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2 "
                  type="text"
                  id="lastName"
                  {...register("lastName", {
                    required: "Last name is required.",
                  })}
                />
                <Error message={errors?.lastName?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="legalBusinessName" className="uppercase">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  Legal Business Name
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2"
                  type="text"
                  id="legalBusinessName"
                  {...register("sensitiveData.legalBusinessName", {
                    required: "Legal Business Name is required.",
                  })}
                />
                <Error
                  message={errors?.sensitiveData?.legalBusinessName?.message}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="typeOfEntity" className="uppercase">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  Type of Entity
                </label>
                <select
                  className="w-full rounded border border-blue-700 p-2"
                  id="typeOfEntity"
                  {...register("sensitiveData.typeOfEntity", {
                    required: "Type of Entity is required.",
                  })}
                >
                  <option value={""}>Please select</option>
                  {TYPEOFENTITY.map((entity) => (
                    <option key={entity.code} value={entity.code}>
                      {entity.name}
                    </option>
                  ))}
                </select>
                <Error message={errors?.sensitiveData?.typeOfEntity?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="legalDOB" className="uppercase">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  Date of Birth
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2"
                  type="date"
                  id="legalDOB"
                  {...register("sensitiveData.legalDOB", {
                    required: "Date of birth is required.",
                    validate: {
                      isFuture: (value) =>
                        !isFuture(parseISO(value)) ||
                        "Selected date is future.",
                    },
                  })}
                />
                <Error message={errors?.sensitiveData?.legalDOB?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="legalEmail" className="uppercase">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  Legal Email
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2"
                  type="email"
                  id="legalEmail"
                  {...register("sensitiveData.legalEmail", {
                    required: "Legal Email is required.",
                    validate: {
                      isEmail: (value) =>
                        /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                          value,
                        ) || "Invalid email.",
                    },
                  })}
                />
                <Error message={errors?.sensitiveData?.legalEmail?.message} />
              </div>
            </div>
            {/* Bank Details */}
            <div>
              <div className="mb-2">
                <label htmlFor="bankAccountNumber" className="uppercase">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  Bank Account Number
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2"
                  type="text"
                  id="bankAccountNumber"
                  {...register("sensitiveData.bankAccountNumber", {
                    required: "Bank account number is required.",
                  })}
                />
                <Error
                  message={errors?.sensitiveData?.bankAccountNumber?.message}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="routingNumber" className="uppercase">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  Bank Routing Number
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2"
                  type="text"
                  id="routingNumber"
                  {...register("sensitiveData.routingNumber", {
                    required: "Bank routing number is required.",
                  })}
                />
                <Error
                  message={errors?.sensitiveData?.routingNumber?.message}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="ssn">
                  <span className="mr-1 text-lg text-red-500">*</span>SSN (Last
                  4)
                </label>
                <input
                  className="w-full rounded border border-blue-700  p-2 "
                  type="text"
                  id="ssn"
                  maxLength={4}
                  {...register("sensitiveData.ssn", {
                    required: "SSN is required.",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a number",
                    },
                    validate: {
                      minMax: (value) =>
                        String(value).length === 4 || "SSN must be 4 digit.",
                    },
                  })}
                />
                <Error message={errors?.sensitiveData?.ssn?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="ein" className="uppercase">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  EIN
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2"
                  type="text"
                  id="ein"
                  placeholder="123456789"
                  maxLength={10}
                  {...register("sensitiveData.ein", {
                    required: "EIN is required.",
                  })}
                />
                <Error message={errors?.sensitiveData?.ein?.message} />
              </div>
              <div className="mb-2">
                <label htmlFor="salesTax" className="uppercase">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  Sales Tax
                </label>
                <input
                  className="w-full rounded border border-blue-700 p-2  "
                  type="number"
                  step={0.01}
                  id="salesTax"
                  min={0}
                  {...register("salesTax", {
                    required: "Sales Tax is required.",
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: "Value should be equal or greater than 0.",
                    },
                  })}
                />
                <Error message={errors?.salesTax?.message} />
              </div>
            </div>
          </div>

          <div className="text-right">
            <Button
              loading={isSubmitting}
              disabled={formSuccess}
              type="submit"
              label="S U B M I T"
              width={48}
              margin={"mr6"}
              color="green"
            />
            <Button
              loading={isSubmitting}
              type="button"
              label=" C A N C E L"
              width={48}
              color="red"
              onClick={closeForm}
            />
          </div>
        </form>
      </div>
      <DoordashDialogConfirm
        handleCloseModal={handleCloseModal}
        handleConfirm={handleSubmit(submit)}
        isSubmitting={isSubmitting}
        showModal={showConfirmModal}
        errors={apiErrors}
        clearError={() => setApiErrors(null)}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const Error = ({ message }) =>
  message ? <p className="m-0 text-xs text-red-600">{message}</p> : null;
