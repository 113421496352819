import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { validateAddress } from "./apiRepository/GeocoderRepository";
import { validateStore } from "./validation/validation";
import { validatePhoneNumber } from "./validation/validatePhoneNumber";

function StoreInfo(props) {
  const { name, phoneNumber, emailAddress, tax } = props.formData.store;
  const { street1, street2, city, state, zipCode } =
    props.formData.store.address;
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(true);

  const { handleSubmit } = useForm();

  const onChangeStoreDetails = (e) => {
    props.setFormData({
      ...props.formData,
      store: {
        ...props.formData.store,
        [e.target.name]: e.target.value,
      },
    });
  };

  const onChangeAddressDetails = (e) => {
    setAddress(true);
    props.setFormData({
      ...props.formData,
      store: {
        ...props.formData.store,
        address: {
          ...props.formData.store.address,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const { isAddressValid } = await validateAddress({
      street1,
      street2,
      city,
      state,
      zipCode,
    });
    setAddress(isAddressValid);
    if (isAddressValid) {
      props.setStep((step) => step + 1);
    }
    setLoading(false);
  };

  const states = [
    { name: "ALABAMA", abbr: "AL" },
    { name: "ALASKA", abbr: "AK" },
    { name: "AMERICAN SAMOA", abbr: "AS" },
    { name: "ARIZONA", abbr: "AZ" },
    { name: "ARKANSAS", abbr: "AR" },
    { name: "CALIFORNIA", abbr: "CA" },
    { name: "COLORADO", abbr: "CO" },
    { name: "CONNECTICUT", abbr: "CT" },
    { name: "DELAWARE", abbr: "DE" },
    { name: "DISTRICT OF COLUMBIA", abbr: "DC" },
    { name: "FEDERATED STATES OF MICRONESIA", abbr: "FM" },
    { name: "FLORIDA", abbr: "FL" },
    { name: "GEORGIA", abbr: "GA" },
    { name: "GUAM", abbr: "GU" },
    { name: "HAWAII", abbr: "HI" },
    { name: "IDAHO", abbr: "ID" },
    { name: "ILLINOIS", abbr: "IL" },
    { name: "INDIANA", abbr: "IN" },
    { name: "IOWA", abbr: "IA" },
    { name: "KANSAS", abbr: "KS" },
    { name: "KENTUCKY", abbr: "KY" },
    { name: "LOUISIANA", abbr: "LA" },
    { name: "MAINE", abbr: "ME" },
    { name: "MARSHALL ISLANDS", abbr: "MH" },
    { name: "MARYLAND", abbr: "MD" },
    { name: "MASSACHUSETTS", abbr: "MA" },
    { name: "MICHIGAN", abbr: "MI" },
    { name: "MINNESOTA", abbr: "MN" },
    { name: "MISSISSIPPI", abbr: "MS" },
    { name: "MISSOURI", abbr: "MO" },
    { name: "MONTANA", abbr: "MT" },
    { name: "NEBRASKA", abbr: "NE" },
    { name: "NEVADA", abbr: "NV" },
    { name: "NEW HAMPSHIRE", abbr: "NH" },
    { name: "NEW JERSEY", abbr: "NJ" },
    { name: "NEW MEXICO", abbr: "NM" },
    { name: "NEW YORK", abbr: "NY" },
    { name: "NORTH CAROLINA", abbr: "NC" },
    { name: "NORTH DAKOTA", abbr: "ND" },
    { name: "NORTHERN MARIANA ISLANDS", abbr: "MP" },
    { name: "OHIO", abbr: "OH" },
    { name: "OKLAHOMA", abbr: "OK" },
    { name: "OREGON", abbr: "OR" },
    { name: "PALAU", abbr: "PW" },
    { name: "PENNSYLVANIA", abbr: "PA" },
    { name: "PUERTO RICO", abbr: "PR" },
    { name: "RHODE ISLAND", abbr: "RI" },
    { name: "SOUTH CAROLINA", abbr: "SC" },
    { name: "SOUTH DAKOTA", abbr: "SD" },
    { name: "TENNESSEE", abbr: "TN" },
    { name: "TEXAS", abbr: "TX" },
    { name: "UTAH", abbr: "UT" },
    { name: "VERMONT", abbr: "VT" },
    { name: "VIRGIN ISLANDS", abbr: "VI" },
    { name: "VIRGINIA", abbr: "VA" },
    { name: "WASHINGTON", abbr: "WA" },
    { name: "WEST VIRGINIA", abbr: "WV" },
    { name: "WISCONSIN", abbr: "WI" },
    { name: "WYOMING", abbr: "WY" },
  ];

  return (
    <form
      id="store-details-form"
      className="text-sm text-gray-800 sm:space-y-1"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-3">
        <label className="mb-1 block text-sm font-medium text-gray-700">
          Store Name <span className="text-secondary">*</span>
        </label>
        <input
          className="focus:border-1 w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
          value={name}
          name="name"
          onChange={onChangeStoreDetails}
          required
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div>
          <label className="mb-1 text-sm font-medium text-gray-700">
            Store Phone Number <span className="text-secondary">*</span>
          </label>
          <input
            className="w-half focus:border-1 block rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
            value={validatePhoneNumber(phoneNumber).formatted}
            name="phoneNumber"
            onChange={onChangeStoreDetails}
            placeholder="(000) 000-0000"
            required
          />
          <span className="mt-1 ml-1 mb-2 text-xs text-red-500">
            {validatePhoneNumber(phoneNumber).isValid
              ? undefined
              : validatePhoneNumber(phoneNumber).error}
          </span>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Store Email Address
          </label>
          <input
            className="w-half focus:border-1 block rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary sm:w-full"
            value={emailAddress}
            name="emailAddress"
            onChange={onChangeStoreDetails}
            placeholder="(Optional)"
          />
          <span className="mt-1 ml-1 mb-2 text-xs text-red-500">
            {validateStore(props.formData).isEmailValid
              ? validateStore(props.formData).emailAddress
              : null}
          </span>
        </div>
      </div>
      <div className="mb-1">
        <label className="mb-1 block text-sm font-medium text-gray-700">
          Store Address <span className="text-secondary">*</span>
        </label>
        <input
          className="focus:border-1 w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
          value={street1}
          name="street1"
          onChange={onChangeAddressDetails}
          placeholder="Address"
          required
        />
      </div>
      <div className="mb-1">
        <input
          className="focus:border-1 w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
          value={street2}
          name="street2"
          onChange={onChangeAddressDetails}
          placeholder="Address 2 (Optional)"
        />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div>
          <input
            className="focus:border-1 w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
            value={city}
            name="city"
            onChange={onChangeAddressDetails}
            placeholder="City"
            required
          />
        </div>
        <div>
          <select
            className="focus:border-1 w-full appearance-none rounded-md border border-gray-300 bg-dropdown bg-right bg-no-repeat bg-origin-content px-3 py-2 outline-none focus:border-secondary"
            value={state}
            name="state"
            onChange={onChangeAddressDetails}
            placeholder="State"
            required
          >
            <option value="" selected disabled>
              State
            </option>
            {states.map((state) => (
              <option value={state.name}>{state.name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="mb-3 grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div>
          <input
            className={`focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary ${
              validateStore(props.formData).isZipValid ? "border-red-400" : ""
            }`}
            value={zipCode}
            name="zipCode"
            onChange={onChangeAddressDetails}
            placeholder="Zip Code"
            required
          />
        </div>
        <div>
          <input
            className="focus:border-1 w-full cursor-not-allowed rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
            value="U.S."
            name="country"
            placeholder="Country"
            readOnly
            disabled
          />
        </div>
      </div>
      <span className="mt-1 ml-1 mb-2 text-xs text-red-500">
        {validateStore(props.formData).isZipValid
          ? validateStore(props.formData).zip
          : !address
          ? "Invalid US Address."
          : undefined}
      </span>
      <div className="mb-1">
        <label className="mb-1 block text-sm font-medium text-gray-700">
          Store Tax <span className="text-secondary">*</span>
        </label>
        <input
          className="focus:border-1 w-half rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary"
          value={tax}
          name="tax"
          onChange={onChangeStoreDetails}
          required
          min="0.01"
          max="100"
          step=".01"
          type="number"
        />
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={
            validateStore(props.formData).isValid ||
            !validatePhoneNumber(phoneNumber).isValid
          }
          variant="contained"
          className="block w-1/3 rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
        >
          {loading ? (
            <div className="flex justify-center">
              <svg
                className="-ml-1 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            "Next"
          )}
        </button>
      </div>
    </form>
  );
}

export default StoreInfo;
