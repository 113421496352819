import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { format, isValid } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import getStoreDetails from "../apiRepository/getStoreDetails";
import deliveryServiceTypes from "../../constants/deliveryServiceTypes";
import disableStoreReasons, {
  disableStoreReasonsObject,
} from "../../constants/disableStoreReasons";
import useStore from "../../hooks/useStore";
import Button from "./Buttons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function DeliverySettings({ deliveryService }) {
  const { store, setStore } = useStore();
  const axiosPrivate = useAxiosPrivate();
  const {
    enabled,
    percentToCharge,
    roundPrice,
    reason,
    note: notes,
  } = store?.deliverySettings?.[
    deliveryServiceTypes?.[deliveryService].cammel
  ] || {};

  let defaultSetting = {
    enabled,
    percentToCharge,
    roundPrice,
  };

  const [settings, setSettings] = useState(defaultSetting);
  const [reasonState, setReasonState] = useState({ reason, notes });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [saveErrors, setSaveErrors] = useState(null);
  const [isAPILoading, setIsAPILoading] = useState(false);
  const [menuSyncing, setMenuSyncing] = useState(false);
  const [snackbar, setSnackbar] = useState({
    severity: "success",
    message: "",
    open: false,
  });

  let disabeSave =
    Array(Object.values(settings)).toString() ===
    Array(Object.values(defaultSetting)).toString();

  const handleEnableDisable = () => {
    setSettings((prev) => ({ ...prev, enabled: !prev.enabled }));
  };

  const handleRoundPrice = (state) => {
    setSettings((prev) => ({ ...prev, roundPrice: state }));
  };

  const handleReset = () => {
    setSettings(defaultSetting);
  };

  const handleCloseSnackbar = (event, reason) => {
    setSnackbar((prev) => ({ ...prev, open: false, message: null }));
  };

  const handleCloseConfirmDialog = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    setSaveErrors(null);
    setShowConfirmDialog(false);
  };

  const handleGetStoreDetails = async () => {
    const storeDetails = await getStoreDetails({
      integratorId: store?.integratorId,
      storeId: store?.storeId,
      token: store?.token,
    }).catch((err) => {
      console.error(err);
    });
    setStore((prev) => ({ ...prev, ...storeDetails }));
  };

  const handleSync = async () => {
    setMenuSyncing(true);
    await axiosPrivate
      .post(
        `/integrator-service/integrators/${store.integratorId}/stores/${store.storeId}/menu-sync`,
        {
          service: deliveryServiceTypes?.[deliveryService].capitalize,
        },
      )
      .then(async ({ data }) => {
        console.log(data);
        // setStore((prev) => ({
        //   ...prev,
        //   deliverySettings: {
        //     ...prev.deliverySettings,
        //     [deliveryService]: {
        //       ...defaultSetting,
        //       ...settings,
        //     },
        //   },
        //   ...storeDetails,
        // }));
        await handleGetStoreDetails();
        setSnackbar({
          severity: "success",
          message: data?.message || "Successfully synced.",
          open: true,
        });
      })
      .catch((err) => {
        console.error(err?.response?.data?.errors || err);
        setSnackbar({
          severity: "error",
          message: err?.response?.data?.errors?.[0],
          open: true,
        });
      })
      .finally(() => {
        setMenuSyncing(false);
      });
  };

  const handleSave = async (data) => {
    setIsAPILoading(true);
    setSaveErrors(null);

    const _settings = { ...settings, ...data };

    if (
      settings?.enabled ||
      deliveryService === deliveryServiceTypes.bevz.cammel
    ) {
      delete _settings.reason;
      delete _settings.notes;
    }

    await axiosPrivate
      .patch(
        `/integrator-service/integrators/${store.integratorId}/stores/${store.storeId}`,
        {
          deliverySettings: {
            type: deliveryServiceTypes?.[deliveryService].cammel,
            settings: _settings,
          },
        },
      )
      .then(async (res) => {
        setReasonState(data);
        // const storeDetails = res?.data?.data || {};
        setShowConfirmDialog(false);
        // setStore((prev) => ({
        //   ...prev,
        //   ...storeDetails,
        //   deliverySettings: {
        //     ...prev.deliverySettings,
        //     [deliveryServiceTypes?.[deliveryService].cammel]: {
        //       ...prev.deliverySettings?.[
        //         deliveryServiceTypes?.[deliveryService].cammel
        //       ],
        //       ..._settings,
        //     },
        //   },
        // }));
        await handleGetStoreDetails();
        setSnackbar({
          severity: "success",
          message: "Successfully updated.",
          open: true,
        });
      })
      .catch((err) => {
        console.error(err?.response?.data?.errors || err);
        setSaveErrors(err?.response?.data?.errors || err.message);
      })
      .finally(() => {
        setIsAPILoading(false);
      });
  };

  return (
    <>
      <div className="p-4">
        <h2 className="text-2xl">
          {deliveryServiceTypes?.[deliveryService].capitalize} Delivery Setting
        </h2>
        <div className="md:flex md:flex-row">
          <div className="mb-10 items-start md:mb-0 md:w-3/6">
            <Button
              width={64}
              color={settings?.enabled ? "red" : "green"}
              label={`${settings?.enabled ? "DISABLE" : "ENABLE"} SERVICE`}
              fontSize="lg"
              onClick={handleEnableDisable}
            />
            {defaultSetting?.enabled ? null : (
              <div className="mt-6">
                <p className="mb-0 font-bold text-red-500">
                  Service is currently DISABLED
                </p>
                {deliveryService === deliveryServiceTypes.bevz.cammel ? null : (
                  <>
                    <p className="mb-0 ">
                      Reason: {disableStoreReasonsObject?.[reasonState.reason]}
                    </p>
                    <p className="mb-0">Notes: {reasonState.notes}</p>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="md:w-3/6">
            <div className="mb-6  text-xl font-bold antialiased ">
              <div className="mb-10">
                <div>
                  Round Price:{" "}
                  <div className="ml-2 inline">
                    <RoundPrice
                      disabled={defaultSetting?.enabled === false}
                      isRounded={settings?.roundPrice === true}
                      onClick={handleRoundPrice.bind(this, true)}
                      color="green"
                    >
                      YES
                    </RoundPrice>
                    <RoundPrice
                      disabled={defaultSetting?.enabled === false}
                      isRounded={settings?.roundPrice === false}
                      onClick={handleRoundPrice.bind(this, false)}
                      color="red"
                    >
                      NO
                    </RoundPrice>
                  </div>
                </div>
                <p className="mb-0 text-xs font-normal text-gray-700">
                  Round Price up to $x.99
                </p>
              </div>
              <div>
                <div>
                  <p className="mb-0 text-xl font-bold">
                    % to Charge:
                    <input
                      className="ml-2 rounded border border-blue-700 p-1 text-center font-bold disabled:cursor-not-allowed disabled:border-gray-600 disabled:bg-gray-600 disabled:text-gray-500 md:w-20 md:text-2xl"
                      type="number"
                      id="charge"
                      min={0}
                      max={100}
                      value={settings.percentToCharge}
                      disabled={defaultSetting.enabled === false}
                      onChange={(e) =>
                        setSettings((prev) => ({
                          ...prev,
                          percentToCharge: e.target.value,
                        }))
                      }
                    />
                  </p>
                </div>
                <div>
                  <p className="mb-0 text-xs font-normal text-gray-700">
                    Price increase for Delivery Service fees.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {disabeSave ? null : (
          <div className="mt-10 border-t-2 border-t-gray-300 pt-5 text-right">
            <Button
              color="green"
              label="S A V E"
              width={48}
              margin="mr6"
              disabled={disabeSave}
              onClick={() => setShowConfirmDialog(true)}
            />
            <Button
              color="red"
              label="C A N C E L"
              width={48}
              onClick={handleReset}
            />
          </div>
        )}
        {deliveryService === deliveryServiceTypes.bevz.cammel ? null : (
          <div className="mt-10 border-t-2 border-t-gray-400 pt-10 ">
            <Button
              color="amber"
              label="SYNC"
              width={48}
              disabled={
                defaultSetting.enabled === false ||
                menuSyncing ||
                store?.deliverySettings?.[
                  deliveryServiceTypes?.[deliveryService].cammel
                ]?.menuSync?.status === "IN_PROGRESS"
              }
              loading={menuSyncing}
              loadingLabel="SYNCING..."
              onClick={handleSync}
              rounded
            />

            <p className="mt-2 font-bold">
              Last Synced:{" "}
              {store?.deliverySettings?.[
                deliveryServiceTypes?.[deliveryService].cammel
              ]?.menuSync?.syncEnd &&
              isValid(
                store?.deliverySettings?.[
                  deliveryServiceTypes?.[deliveryService].cammel
                ]?.menuSync?.syncEnd,
              )
                ? format(
                    store?.deliverySettings?.[
                      deliveryServiceTypes?.[deliveryService].cammel
                    ]?.menuSync?.syncEnd,
                    "MMM dd yyyy hh:mm a",
                  )
                : null}
              <br />
              Status:{" "}
              {store?.deliverySettings?.[
                deliveryServiceTypes?.[deliveryService].cammel
              ]?.menuSync?.status?.replace(/_/g, " ")}
              {store?.deliverySettings?.[
                deliveryServiceTypes?.[deliveryService].cammel
              ]?.menuSync?.status === "IN_PROGRESS" ? (
                <>
                  {" "}
                  <button
                    className="rounded bg-red-500 px-2 text-white"
                    onClick={handleGetStoreDetails}
                  >
                    Refresh
                  </button>
                </>
              ) : null}
            </p>
          </div>
        )}
      </div>
      <ConfirmDialog
        errors={saveErrors}
        clearError={() => setSaveErrors(null)}
        handleConfirm={handleSave}
        handleClose={handleCloseConfirmDialog}
        show={showConfirmDialog}
        isSubmitting={isAPILoading}
        deliveryService={deliveryService}
        settings={settings}
        defaultSettings={defaultSetting}
        setSettings={setSettings}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const RoundPrice = ({ isRounded, onClick, color, disabled, children }) => {
  const colors = {
    red: "border-red-600 bg-red-500 text-white",
    green: "border-green-700 bg-green-700 text-white",
  };
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`w-20 rounded border p-2 text-center font-bold disabled:cursor-not-allowed disabled:bg-gray-600 disabled:text-gray-400 ${
        isRounded && !disabled ? colors?.[color] : ""
      }`}
    >
      {children}
    </button>
  );
};

const ConfirmDialog = ({
  show,
  handleClose,
  errors,
  isSubmitting,
  handleConfirm,
  clearError,
  deliveryService,
  settings,
  defaultSettings,
  setSettings,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formError },
  } = useForm();

  return (
    <Dialog
      open={show}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
    >
      {" "}
      <form onSubmit={handleSubmit(handleConfirm)}>
        <DialogTitle>CONFIRM</DialogTitle>
        <DialogContent>
          {defaultSettings.enabled === true &&
          settings.enabled === false &&
          deliveryService !== deliveryServiceTypes.bevz.cammel ? (
            <div>
              <p className="mb-0 text-lg">
                Before confirming, please tell us why you're disabling this
                service.
              </p>
              <div className="mb-6">
                <label htmlFor="reason" className="w-full text-lg font-bold">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  Reason
                </label>
                <select
                  id="reason"
                  className=" w-full border p-2 text-lg"
                  {...register("reason", { required: "Reason is required." })}
                >
                  <option value={""}>- Please select -</option>
                  {disableStoreReasons.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {formError?.reason?.message && (
                  <p className="m-0 text-sm text-red-600">
                    {formError?.reason?.message}
                  </p>
                )}
              </div>
              <div>
                <label htmlFor="notes" className="w-full text-lg font-bold">
                  <span className="mr-1 text-lg text-red-500">*</span>
                  Notes
                </label>
                <textarea
                  {...register("notes", { required: "Notes is required." })}
                  id="notes"
                  className="w-full border p-2 text-lg"
                ></textarea>
                {formError?.notes?.message && (
                  <p className="m-0 text-sm text-red-600">
                    {formError?.notes?.message}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to save?
            </DialogContentText>
          )}
          {errors ? (
            <Alert severity="error" onClose={clearError}>
              <AlertTitle>Failed</AlertTitle>
              {Array.isArray(errors)
                ? errors.map((error) => (
                    <p className="mb-0 capitalize">{error}</p>
                  ))
                : errors}
            </Alert>
          ) : null}
        </DialogContent>

        <DialogActions sx={{ pb: "1.5rem", px: "1.5rem" }}>
          <Button
            loading={isSubmitting}
            type="submit"
            width={48}
            margin={"mdmr6"}
            color="green"
          />

          <Button
            loading={isSubmitting}
            loadingLabel="C A N C E L"
            label="C A N C E L"
            width={48}
            color="red"
            onClick={handleClose}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
