import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StoreHours from "../../components/StoreHours";
import StoreInfo from "../../components/StoreInfo";
import {
  checkAuth,
  getStoreSubscriptionDetails,
} from "../../components/apiRepository/DynamodbRepository";
import AccountInfoV21 from "../../components/multistore/AccountInfoV21";
import useStore from "../../hooks/useStore";
import Confirmation from "./Confirmation";
import { parseIntegratorId } from "../../helpers/integratorId";

export default function FormCreateStore() {
  const { owner, setOwner } = useStore();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    accountType: "STORE",
    account: {
      username: "", // OWNER - not required | STORE - required
      password: "",
      ownerId: owner?.ownerId,
    },
    store: {
      name: "",
      address: {
        street1: "",
        street2: "",
        city: "",
        state: "",
        zipCode: "",
      },
      phoneNumber: "",
      emailAddress: "",
    },
    operationHours: [
      {
        day: "Sunday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Monday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Tuesday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Wednesday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Thursday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Friday",
        startTime: "",
        endTime: "",
      },
      {
        day: "Saturday",
        startTime: "",
        endTime: "",
      },
    ],
    eula: true,
    newSignUp: true,
    storeId: "",
    integratorId: parseIntegratorId(),
  });

  const formTitles = [
    "",
    "Add New Store",
    "Store Information",
    "Store Operation Hours",
    "Confirmation",
  ];

  const formSubTitles = [
    "",
    "Please create a username and password for your store",
    "Fill in store details.",
    "Set business hours for your store.",
    "Please confirm your new store addition",
  ];

  const formSubTitle2 = [
    "",
    "Each store must have its own unique account. This username and password will be used to log into your store tablet.",
  ];

  const handleStep = () => {
    if (step === 1) {
      navigate("/account");
    }

    setStep((prev) => {
      const _step = prev - 1;
      return _step <= 1 ? 1 : _step;
    });
  };

  const handlePageDisplay = (step) => {
    if (step <= 1) {
      return (
        <AccountInfoV21
          formData={formData}
          setFormData={setFormData}
          step={step}
          setStep={setStep}
        />
      );
    } else if (step === 2) {
      return (
        <StoreInfo
          formData={formData}
          setFormData={setFormData}
          step={step}
          setStep={setStep}
        />
      );
    } else if (step === 3) {
      return (
        <StoreHours
          formData={formData}
          setFormData={setFormData}
          step={step}
          setStep={setStep}
        />
      );
    } else if (step === 4) {
      return <Confirmation formData={formData} />;
    }
  };

  useEffect(() => {
    async function checkSession() {
      await checkAuth({ token: owner?.token }).catch(() => navigate("/login"));
    }

    owner?.token && checkSession();
  }, [step, owner?.token, navigate]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getStoreSubscription = async () => {
      try {
        const response = await getStoreSubscriptionDetails({
          storeId: owner?.storeId,
          header: {
            signal: controller.signal,
          },
        });

        isMounted &&
          setOwner((prev) => ({
            ...prev,
            activeStoreCount: response?.activeStores?.length,
          }));
      } catch (err) {
        console.error(err);
      }
    };

    owner?.storeId && !owner?.activeStoreCount && getStoreSubscription();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [owner?.storeId, setOwner, owner?.activeStoreCount]);

  return (
    <>
      {step !== 0 ? (
        <div className="flex items-center">
          {step === 0 ? (
            ""
          ) : (
            <button onClick={handleStep}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2 h-5 w-5 text-primary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          )}
          <p className="rem-margin mb-0 text-sm text-secondary">
            Step {step} of {formTitles.length - 1}
          </p>
        </div>
      ) : (
        ""
      )}
      <p className="rem-margin mt-4 mb-0 text-2xl font-semibold text-primaryDark">
        {formTitles[step]}
      </p>
      <p className="mb-0 text-sm text-form">{formSubTitles[step]}</p>
      <p className="mb-4 text-xs text-form">{formSubTitle2[step]}</p>
      <div>{handlePageDisplay(step)}</div>
    </>
  );
}
