import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LoadingSVG from "../components/multistore/LoadingSVG";
import PageHeader from "../components/multistore/PageHeader";
import { postForgotPassword } from "../components/apiRepository/DynamodbRepository";
import { MdLocalPostOffice } from "react-icons/md";

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState(null);
  const [success, setSuccess] = useState(false);
  const emailRef = useRef({});
  emailRef.current = watch("email", "");

  const handleForgotPassword = async (data) => {
    try {
      setApiError(null);
      await postForgotPassword(data);
      setSuccess(true);
    } catch (error) {
      setApiError(error.response.data?.errors);
      console.error(error.response.data?.errors);
    }
  };

  return (
    <>
      {!success ? (
        <div>
          <div>
            <div
              className="flex w-fit cursor-pointer items-center"
              onClick={() => navigate("/login")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2 h-5 w-5 text-primary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
              <span className="rem-margin text-sm text-secondary">Back</span>
            </div>
          </div>
          <PageHeader
            title={"Forgot Password"}
            subTitle={"Please enter your Bevz email account to reset password"}
          />
          <form onSubmit={handleSubmit(handleForgotPassword)}>
            <div>
              <label className="block text-xs font-bold text-label2">
                Email Address <span className="text-secondary">*</span>
              </label>
              <input
                className={`focus:border-1 block w-full rounded-md border border-gray-300 px-3 py-2 outline-none focus:border-secondary
                ${errors?.email ? "border-red-400" : ""}`}
                type="text"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Email is required!",
                  },
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                    message: "Please enter valid email address.",
                  },
                  onChange: () => setApiError(null),
                })}
              />
              {errors?.email ? (
                <span className="mt-1 ml-1 mb-2 text-xs text-red-500">
                  {errors?.email?.message}
                </span>
              ) : null}
            </div>
            {(apiError || []).map((error) => (
              <div className="text-xs text-red-500">{error}</div>
            ))}

            <div className="mt-4 flex justify-end">
              <button
                className="block w-1/3 rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <LoadingSVG /> : "Reset Password"}
              </button>
            </div>
          </form>
        </div>
      ) : null}
      {success ? <Success email={emailRef.current} /> : null}
    </>
  );
}

function Success({ email }) {
  const navigate = useNavigate();
  return (
    <div className="flex">
      <div className="flex w-24 items-start justify-center">
        <div className="mt-8 rounded-full bg-green-700 p-2">
          <MdLocalPostOffice size={40} color="white" />
        </div>
      </div>
      <div>
        <h1 className="mb-4 text-xl font-bold">Check your email</h1>
        <p className="mb-5">
          We've sent password reset instructions to:
          <br />
          <span className="text-base font-medium">{email}</span>
        </p>
        <p className="mb-6">
          If it doesn't arrive soon, check your spam foler.
        </p>

        <button
          className="rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:bg-gray-400"
          type="button"
          onClick={() => navigate("/login", { replace: true })}
        >
          Back to Log In
        </button>
      </div>
    </div>
  );
}

// Password link sent to your email!
