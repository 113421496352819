import React, { useState } from "react";
import { createStore } from "./apiRepository/StripeRepository";
import { isValidUrl } from "../helpers/isValidUrl";

function ConfirmModal(props) {
  const { eula } = props.formData;
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState();
  //const integratorCallback = sessionStorage.getItem("integrator_callback");
  //const integratorId = sessionStorage.getItem("integrator_id");
  //const auth = sessionStorage.getItem("integrator_auth");
  const callbackJoin = `https://www.bevz.com/welcome`;

  const subscribe = async () => {
    setLoading(true);
    try {
      const response = await createStore({
        ...props.formData,
      });
      let redirect;
      if (response.data.checkoutLink) {
        redirect = response.data.checkoutLink;
      } else {
        redirect = callbackJoin;
      }

      window.location.replace(redirect);

      return response;
    } catch (e) {
      if (e.response.data.status === 400) {
        setErr(e.response.data.errors);
      }
    }
  };

  const onChangeEula = () => {
    props.setFormData({
      ...props.formData,
      eula: !eula,
    });
  };

  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="max-w-sm rounded bg-gray-200 py-5 px-6 text-gray-800 shadow-xl">
        <div className="flex items-center justify-between">
          {err ? (
            <h4 className="text-lg font-bold text-red-600">Error</h4>
          ) : (
            <h4 className="text-lg font-bold text-primaryDark">Confirm</h4>
          )}
        </div>
        <div className="mt-2 text-justify text-sm text-gray-700">
          {err ? (
            <div>
              <p>
                There was an error processing your request. Please go back and
                ensure all details provided are complete and correct.
              </p>
              <div>Error/s:</div>
              <div className="p-4">
                <ul className="list-disc">
                  {err.map((e) => (
                    <li>{e}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <p>
              Please ensure all details provided are correct. If you click
              Create Store and Checkout, you won't be able to go back and change
              the details you entered.
            </p>
          )}
        </div>
        {err ? (
          ""
        ) : (
          <div className="mt-10 mb-5 flex items-center text-xs text-gray-700">
            <input
              className="mr-3 h-7 w-7 cursor-pointer rounded accent-primary disabled:cursor-not-allowed"
              type="checkbox"
              checked={eula}
              onChange={onChangeEula}
            ></input>
            <span>
              By using this application, you agree to the{" "}
              <a
                className="text-secondary underline"
                href="https://www.bevz.com/terms-of-service"
                target="_blank"
              >
                End User License Agreement{" "}
              </a>
              and the{" "}
              <a
                className="text-secondary underline"
                href="https://www.bevz.com/privacy-policy/"
                target="_blank"
              >
                Privacy Statement
              </a>
              .
            </span>
          </div>
        )}
        <div className="mt-3 flex justify-end space-x-3">
          <button
            className="rounded px-3 py-1 transition duration-300 hover:bg-red-300 hover:bg-opacity-50 hover:text-red-900"
            onClick={() => props.setModal(false)}
          >
            {err ? "OK" : "Cancel"}
          </button>
          {err ? (
            ""
          ) : (
            <button
              className="w-half rounded bg-primary p-2 px-3 py-1 text-white transition duration-300 hover:bg-secondary disabled:cursor-not-allowed disabled:bg-gray-300"
              onClick={() => subscribe().then((data) => console.log(data))}
              disabled={!eula}
            >
              {loading ? (
                <div className="flex justify-center">
                  <svg
                    className="-ml-1 h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                "Create Store and Checkout"
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
