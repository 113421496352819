import React, { useState } from "react";
import ConfirmModalV2 from "./ConfirmModalV2";
import Email from "../assets/images/email.png";
import CreateStoreInfo from "./CreateStoreInfo";

function IntegratorPage(props) {
  const [checkoutModal, setCheckoutModal] = useState(false);

  return (
    <div>
      <CreateStoreInfo {...props} />
      <div>
        <div className="relative mt-2 mb-4">
          <button
            id="sign-up-btn"
            onClick={() => setCheckoutModal(true)}
            className="block w-full rounded bg-primary p-2 text-white transition duration-300 hover:bg-secondary disabled:cursor-not-allowed disabled:bg-gray-300"
          >
            Create Store
          </button>
        </div>
      </div>
      {checkoutModal && (
        <ConfirmModalV2
          setModal={setCheckoutModal}
          setStep={props.setStep}
          formData={props.formData}
          setFormData={props.setFormData}
        />
      )}
    </div>
  );
}

export default IntegratorPage;
